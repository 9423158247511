import React, { useState } from "react";

const Base64Encoder = () => {
  const [input, setInput] = useState("");
  const [output, setOutput] = useState("");
  const [copySuccess, setCopySuccess] = useState(""); 
  const handleEncode = () => {
    const encoded = btoa(input);
    setCopySuccess("");
    setOutput(encoded);
  };

  const handleCopyToClipboard = () => {
    if (output) {
      navigator.clipboard.writeText(output).then(
        () => {
          setCopySuccess("Copied to clipboard!");
        },
        (err) => {
          setCopySuccess("Failed to copy.");
        }
      );
    }

  };
  
  return (
    <div className="container py-5">
      <div className="row">
      <div className="col-lg-3 d-none d-lg-block">
          <div className="border bg-light p-3 text-center vertical-ads">
            {/* Placeholder for Ads */}
          </div>
        </div>
        <div className="col-lg-6">
          <h1>Base64 Encoder</h1>
          <textarea
            className="form-control mb-3"
            rows="4"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Enter text to encode"
          ></textarea>
          <button className="btn btn-primary mb-3" onClick={handleEncode}>
            Base64 Encode
          </button>
          <div className="position-relative mb-3">
            <textarea
              className="form-control"
              rows="4"
              readOnly
              value={output}
              placeholder="Encoded result will appear here"
              style={{ paddingRight: "40px" }} // Space for the icon
            ></textarea>
            {output && (
              <button
                className="btn copy-icon"
                onClick={handleCopyToClipboard}
                aria-label="Copy to clipboard"
                style={{
                  position: "absolute",
                  top: "20%",
                  right: "-10px",
                  transform: "translateY(-50%)",
                  backgroundColor: "transparent",
                  border: "none",
                  cursor: "pointer",
                  color: "#007bff",
                  fontSize: "1.2rem",
                }}
              >📋</button>
            )}
          </div>
          {copySuccess && <span className="text-success mt-2">{copySuccess}</span>}


            <h2>What is Base64 Encoding?</h2>
            <p>Base64 encoding is a process that converts binary data into a textual format using a set of 64 ASCII characters. This encoding ensures that binary data can be safely transmitted or stored using systems that handle only textual data, such as email protocols and web-based applications.</p>

            <h2>Why Use Base64 Encoding?</h2>
            <p>Base64 encoding is typically used to ensure that binary data (such as images, files, or non-printable characters) can be safely transferred or embedded in text-based environments. It helps avoid data corruption caused by non-printable or special characters during transmission or storage.</p>

            <h3>How Does Base64 Encoding Work?</h3>
            <p>Base64 encoding works by dividing binary data into smaller chunks and converting those chunks into readable characters. The steps are as follows:</p>
            <ol>
                <li><strong>Convert Binary Data:</strong> The input data is converted into binary form.</li>
                <li><strong>Divide into 6-Bit Chunks:</strong> Binary data is split into groups of 6 bits, as each Base64 character represents 6 bits of information.</li>
                <li><strong>Map to Base64 Characters:</strong> Each 6-bit chunk is mapped to one of the 64 predefined characters (A-Z, a-z, 0-9, +, and /).</li>
                <li><strong>Add Padding:</strong> If the total number of bits in the input data is not divisible by 24, padding characters (<code>=</code>) are added to make the encoded output a multiple of 4 characters in length.</li>
            </ol>
            <p>For example, the string <code>"Hello"</code> in Base64 encoding becomes <code>SGVsbG8=</code>.</p>

            <h3>Applications of Base64 Encoding</h3>
            <p>Base64 encoding is widely used in various fields, including:</p>
            <ul>
                <li><strong>Web Development:</strong> Embedding small images, fonts, or other binary assets directly into HTML or CSS as inline data.</li>
                <li><strong>Email Transmission:</strong> Encoding attachments to ensure compatibility across different email clients.</li>
                <li><strong>Data URIs:</strong> Creating inline representations of resources in web pages, reducing the need for additional HTTP requests.</li>
                <li><strong>API Communication:</strong> Encoding binary data like JSON Web Tokens (JWT) or other payloads for safe transmission over text-based protocols.</li>
            </ul>

            <h3>How to Perform Base64 Encoding?</h3>
            <p>Base64 encoding can be done using various programming languages or tools. Here's an example in Python:</p>
            <div className="code-part">
            <code class="language-python">
            import base64

            # Input string
            input_str = "Hello"
            # Encode the string
            encoded_bytes = base64.b64encode(input_str.encode("utf-8"))
            encoded_str = encoded_bytes.decode("utf-8")

            print(encoded_str)  # Output: SGVsbG8=
            </code>
            </div>

            <h3>Benefits of Base64 Encoding</h3>
            <p>Base64 encoding provides several advantages, including:</p>
            <ul>
                <li><strong>Compatibility:</strong> Allows binary data to be transferred over text-based protocols without corruption.</li>
                <li><strong>Ease of Embedding:</strong> Makes it possible to include binary resources directly in text-based documents like HTML or XML.</li>
                <li><strong>Wide Support:</strong> Supported by almost all programming languages and tools.</li>
            </ul>

            <h3>Conclusion</h3>
            <p>Base64 encoding is an essential technique for safely converting binary data into a textual format. It is widely used in web development, email systems, and APIs to ensure data integrity during transmission. By understanding how Base64 encoding works, developers can optimize data handling in various text-based environments.</p>
            <p>Interested reading more about Base64. Find this Wiki <a href="https://en.wikipedia.org/wiki/Base64">link</a>. </p>
        </div>
        <div className="col-lg-3">
          <div className="border bg-light p-3 text-center vertical-ads">
            {/* Placeholder for Ads */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Base64Encoder;
