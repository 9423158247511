import React, { useState } from "react";

const ColorMixer = () => {
  const [color1, setColor1] = useState("#ff0000");
  const [color2, setColor2] = useState("#0000ff");
  const [mixRatio, setMixRatio] = useState(50);

  const mixColors = (c1, c2, ratio) => {
    const r1 = parseInt(c1.slice(1, 3), 16);
    const g1 = parseInt(c1.slice(3, 5), 16);
    const b1 = parseInt(c1.slice(5, 7), 16);

    const r2 = parseInt(c2.slice(1, 3), 16);
    const g2 = parseInt(c2.slice(3, 5), 16);
    const b2 = parseInt(c2.slice(5, 7), 16);

    const mix = (value1, value2) =>
      Math.round(value1 * (ratio / 100) + value2 * (1 - ratio / 100));

    const r = mix(r1, r2);
    const g = mix(g1, g2);
    const b = mix(b1, b2);

    return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
  };

  const mixedColor = mixColors(color1, color2, mixRatio);

  return (
    <div className="container py-5">
      <div className="row">
        <div className="col-lg-3 d-none d-lg-block">
          <div className="border bg-light p-3 text-center vertical-ads">
            {/* Placeholder for Ads */}
          </div>
        </div>
        <div className="col-lg-6">
          <h1>Online Color Mixer Tool</h1>
          <p>
            The <strong>Online Color Mixer</strong> is your go-to tool for creating unique color blends. Choose two colors, adjust the mixing ratio, and instantly generate a new color with its corresponding hexadecimal value. Perfect for designers, developers, and artists, this tool simplifies the process of color selection and combination.
          </p>

          <div className="mb-4">
            <label className="form-label">Color 1</label>
            <input
              type="color"
              className="form-control form-control-color"
              value={color1}
              onChange={(e) => setColor1(e.target.value)}
            />
          </div>

          <div className="mb-4">
            <label className="form-label">Color 2</label>
            <input
              type="color"
              className="form-control form-control-color"
              value={color2}
              onChange={(e) => setColor2(e.target.value)}
            />
          </div>

          <div className="mb-4">
            <label className="form-label">Mix Ratio: {mixRatio}%</label>
            <input
              type="range"
              className="form-range"
              min="0"
              max="100"
              value={mixRatio}
              onChange={(e) => setMixRatio(e.target.value)}
            />
          </div>

          <div
            className="p-5 text-center border rounded"
            style={{
              backgroundColor: mixedColor,
              color: "#fff",
              fontWeight: "bold",
            }}
          >
            Mixed Color: {mixedColor.toUpperCase()}
          </div>

          <h2>What is the Online Color Mixer Tool?</h2>
          <p>
            The <strong>Online Color Mixer Tool</strong> helps you blend two colors and create custom shades effortlessly. It is widely used by web designers, graphic artists, and hobbyists who want to explore new color palettes and achieve perfect color harmony.
          </p>

          <h2>How Does the Color Mixer Tool Work?</h2>
          <p>Here's how the Online Color Mixer works:</p>
          <ol>
            <li>
              Select two colors using the color pickers. You can input hex values or choose directly from the palette.
            </li>
            <li>
              Adjust the mix ratio slider to control the contribution of each color in the final blend. A ratio of 50% means equal blending of both colors.
            </li>
            <li>
              View the mixed color result in real-time, along with its hexadecimal code.
            </li>
          </ol>

          <h3>Example of Color Mixing</h3>
          <p>Mixing <strong>red (#FF0000)</strong> and <strong>blue (#0000FF)</strong> with a 50% ratio results in a beautiful purple shade (#800080).</p>

          <h2>Applications of the Online Color Mixer</h2>
          <p>Color mixing is a critical aspect of design and art. Here are some applications:</p>
          <ul>
            <li>
              <strong>Web Design:</strong> Use the tool to create color gradients, palettes, and themes.
            </li>
            <li>
              <strong>Graphic Design:</strong> Experiment with colors for logos, branding, and marketing materials.
            </li>
            <li>
              <strong>Interior Design:</strong> Blend colors for furniture, walls, or decor to achieve the perfect harmony.
            </li>
            <li>
              <strong>Art Projects:</strong> Visualize mixed colors for paintings, sketches, or digital art.
            </li>
          </ul>

          <h2>Why Choose the Online Color Mixer?</h2>
          <p>
            The Online Color Mixer is a free and intuitive tool designed to save time and provide accurate results. Whether you're working on a professional project or exploring your creativity, this tool ensures seamless blending with user-friendly controls.
          </p>

          <h2>Conclusion</h2>
          <p>
            The <strong>Online Color Mixer Tool</strong> is your trusted companion for color blending and creativity. Start exploring endless possibilities and enhance your projects with customized colors.
          </p>
        </div>
        <div className="col-lg-3">
          <div className="border bg-light p-3 text-center vertical-ads">
            {/* Placeholder for Ads */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ColorMixer;
