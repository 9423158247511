import React, { useState } from "react";

const URLEncoder = () => {
  const [input, setInput] = useState("");
  const [output, setOutput] = useState("");
  const [copySuccess, setCopySuccess] = useState(""); 
  const handleEncode = () => {
    setOutput(encodeURIComponent(input));
    setCopySuccess("");
  };

  const handleCopyToClipboard = () => {
    if (output) {
      navigator.clipboard.writeText(output).then(
        () => {
          setCopySuccess("Copied to clipboard!");
        },
        (err) => {
          setCopySuccess("Failed to copy.");
        }
      );
    }

  };
  return (
    <div className="container py-5">
      <div className="row">
        <div className="col-lg-3 d-none d-lg-block">
          <div className="border bg-light p-3 text-center vertical-ads">
            {/* Placeholder for Ads */}
          </div>
        </div>
        <div className="col-lg-6">
          <h1>URL Encoder</h1>
          <textarea
            className="form-control mb-3"
            rows="4"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Enter text to encode"
          ></textarea>
          <button className="btn btn-primary mb-3" onClick={handleEncode}>
            URL Encode
          </button>
          <div className="position-relative mb-3">
            <textarea
              className="form-control"
              rows="4"
              readOnly
              value={output}
              placeholder="Encoded result will appear here"
              style={{ paddingRight: "40px" }} // Space for the icon
            ></textarea>
            {output && (
              <button
                className="btn copy-icon"
                onClick={handleCopyToClipboard}
                aria-label="Copy to clipboard"
                style={{
                  position: "absolute",
                  top: "20%",
                  right: "-10px",
                  transform: "translateY(-50%)",
                  backgroundColor: "transparent",
                  border: "none",
                  cursor: "pointer",
                  color: "#007bff",
                  fontSize: "1.2rem",
                }}
              >📋</button>
            )}
          </div>{copySuccess && <span className="text-success mt-2">{copySuccess}</span>}

            <h2>What is a URL Encoder?</h2>
            <p>A URL encoder is a tool or algorithm used to convert special characters in a URL into a percent-encoded format. URL encoding ensures that URLs are safely transmitted over the internet by replacing unsafe or reserved characters with their encoded equivalents, following the UTF-8 character set.</p>

            <h2>Why is URL Encoding Important?</h2>
            <p>URL encoding is crucial because URLs can only contain specific characters (letters, digits, and a few special symbols). Reserved characters, such as spaces or symbols like <code>&lt;</code> and <code>&gt;</code>, must be encoded to ensure compatibility with web browsers and servers. Encoding prevents errors during transmission and ensures the integrity of the data.</p>

            <h3>How Does URL Encoding Work?</h3>
            <p>URL encoding replaces unsafe or reserved characters with a <code>%</code> symbol followed by two hexadecimal digits representing the ASCII value of the character. The process includes:</p>
            <ol>
                <li><strong>Identifying Characters to Encode:</strong> Characters not in the alphanumeric set (A-Z, a-z, 0-9) and a few safe symbols (<code>-</code>, <code>_</code>, <code>.</code>, <code>~</code>) are encoded.</li>
                <li><strong>Replacing with Percent-Encoding:</strong> Each unsafe character is replaced with its encoded equivalent (e.g., a space becomes <code>%20</code>).</li>
                <li><strong>Encoding the Entire String:</strong> The entire string is processed to ensure all reserved characters are encoded.</li>
            </ol>
            <p>For example, the string <code>"Hello World!"</code> becomes <code>Hello%20World%21</code> after URL encoding.</p>

            <h2>Applications of URL Encoder</h2>
            <p>URL encoders are widely used in various scenarios, such as:</p>
            <ul>
                <li><strong>Query Parameters:</strong> Encoding data passed in URL query strings to ensure proper transmission.</li>
                <li><strong>API Communication:</strong> Encoding payloads or parameters to prevent errors in HTTP requests.</li>
                <li><strong>Data Storage:</strong> Encoding strings to safely store special characters in databases.</li>
                <li><strong>Web Forms:</strong> Encoding form data before sending it to the server.</li>
            </ul>

            <h3>How to Perform URL Encoding?</h3>
            <p>URL encoding can be performed using programming languages or online tools. Here's an example in Python:</p>
            <div className="code-part">
            <code class="language-python">
            import urllib.parse<br/>

            # Input string<br/>
            input_string = "Hello World!"<br/>
            # Encode the string<br/>
            encoded_string = urllib.parse.quote(input_string)<br/>

            print(encoded_string)  # Output: Hello%20World%21<br/>
            </code>
            </div>

            <h2>Common URL Encoding Patterns</h2>
            <p>Here are some commonly encoded characters and their equivalents:</p>
            <table>
                <thead>
                    <tr>
                        <th>Character</th>
                        <th>Encoded Value</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Space</td>
                        <td>%20</td>
                    </tr>
                    <tr>
                        <td>!</td>
                        <td>%21</td>
                    </tr>
                    <tr>
                        <td>#</td>
                        <td>%23</td>
                    </tr>
                    <tr>
                        <td>$</td>
                        <td>%24</td>
                    </tr>
                    <tr>
                        <td>&</td>
                        <td>%26</td>
                    </tr>
                    <tr>
                        <td>'</td>
                        <td>%27</td>
                    </tr>
                    <tr>
                        <td>(</td>
                        <td>%28</td>
                    </tr>
                </tbody>
            </table>

            <h2>Advantages of URL Encoding</h2>
            <p>URL encoding provides several benefits:</p>
            <ul>
                <li><strong>Improved Compatibility:</strong> Ensures URLs are safely transmitted and understood by browsers and servers.</li>
                <li><strong>Error Prevention:</strong> Avoids errors caused by special or reserved characters in URLs.</li>
                <li><strong>Data Integrity:</strong> Ensures that the original data is preserved during transmission.</li>
            </ul>

            <h2>Conclusion</h2>
            <p>URL encoding is a fundamental process for ensuring the safe and error-free transmission of data over the internet. Whether you’re working with APIs, query parameters, or web forms, understanding how URL encoding works is crucial for effective web development. Use an URL encoder to seamlessly encode your data and maintain compatibility across web systems.</p>
            <p>Interested reading more about URL Encode? Find this PHP <a href="https://www.php.net/manual/en/function.urlencode.php">link</a>. </p>                     
        </div>
        <div className="col-lg-3">
          <div className="border bg-light p-3 text-center vertical-ads">
            {/* Placeholder for Ads */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default URLEncoder;
