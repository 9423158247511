import React, { useState } from "react";
import CryptoJS from "crypto-js";

const SHA512HashGenerator = () => {
  const [input, setInput] = useState("");
  const [hash, setHash] = useState("");
  const [copySuccess, setCopySuccess] = useState(""); 
  const handleGenerateHash = () => {
    const hashResult = CryptoJS.SHA512(input).toString();
    setCopySuccess("");
    setHash(hashResult);
  };

  const handleCopyToClipboard = () => {
    if (hash) {
      navigator.clipboard.writeText(hash).then(
        () => {
          setCopySuccess("Copied to clipboard!");
        },
        (err) => {
          setCopySuccess("Failed to copy.");
        }
      );
    }

  };
  return (
    <div className="container py-5">
      <div className="row">
        <div className="col-lg-3 d-none d-lg-block">
          <div className="border bg-light p-3 text-center vertical-ads">
            {/* Placeholder for Ads */}
          </div>
        </div>
        <div className="col-lg-6">
          <h1>SHA-512 Hash Generator</h1>
          <textarea
            className="form-control mb-3"
            rows="4"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Enter text to hash"
          ></textarea>
          <button className="btn btn-primary mb-3" onClick={handleGenerateHash}>
            Generate SHA-512 Hash
          </button>
          <div className="position-relative mb-3">
            <textarea
              className="form-control"
              rows="4"
              readOnly
              value={hash}
              placeholder="Hash result will appear here"
              style={{ paddingRight: "40px" }} // Space for the icon
            ></textarea>
            {hash && (
              <button
                className="btn copy-icon"
                onClick={handleCopyToClipboard}
                aria-label="Copy to clipboard"
                style={{
                  position: "absolute",
                  top: "20%",
                  right: "-10px",
                  transform: "translateY(-50%)",
                  backgroundColor: "transparent",
                  border: "none",
                  cursor: "pointer",
                  color: "#007bff",
                  fontSize: "1.2rem",
                }}
              >📋</button>
            )}
          </div>
          {copySuccess && <span className="text-success mt-2">{copySuccess}</span>}

            <h2>What is a SHA-512 Hash Generator?</h2>
            <p>A SHA-512 (Secure Hash Algorithm 512-bit) hash generator is a cryptographic tool used to create a fixed 512-bit hash value (commonly represented as a 128-character hexadecimal string) from input data. It is part of the SHA-2 family of algorithms and offers robust security for data integrity, digital signatures, and password hashing.</p>

            <h2>How Does SHA-512 Hashing Work?</h2>
            <p>SHA-512 generates a secure hash value by processing input data through a series of cryptographic operations. Here's how it works:</p>
            <ol>
                <li><strong>Message Padding:</strong> The input data is padded so that its length becomes a multiple of 1024 bits.</li>
                <li><strong>Message Segmentation:</strong> The padded message is divided into 1024-bit blocks for processing.</li>
                <li><strong>Hash Initialization:</strong> Eight 64-bit initial hash values are defined by the algorithm as constants.</li>
                <li><strong>Block Processing:</strong> Each block undergoes 80 iterations of mathematical operations, including bitwise logic, modular additions, and message compression functions.</li>
                <li><strong>Final Hash Output:</strong> The resulting 512-bit hash is represented as a 128-character hexadecimal string.</li>
            </ol>

            <h3>Example of SHA-512 Hashing</h3>
            <p>Here's an example of generating a SHA-512 hash in Python:</p>
            <div className="code-part">
                <code class="language-python">
                import hashlib<br/>

                # Input string<br/>
                input_data = "Hello, World!"<br/>
                # Generate SHA-512 hash<br/>
                sha512_hash = hashlib.sha512(input_data.encode()).hexdigest()<br/>

                print(sha512_hash)<br/>
                # Output: 2c74fd17edafd80e8447b0d46741ee243b7eb74f52e42b5188f3bf3c4f6ed90c<br/>
                #         0bff882c31f996ee2cd46bace36a20e2b9f76890bcd65863e254d7f0dcbc8228<br/>
                </code>
            </div>

            <h2>Applications of SHA-512 Hash Generator</h2>
            <p>SHA-512 hash generators are widely used in various fields, including:</p>
            <ul>
                <li><strong>Data Integrity:</strong> Verifying that files or data have not been altered during transmission or storage.</li>
                <li><strong>Digital Signatures:</strong> Creating secure and tamper-proof digital signatures for documents and transactions.</li>
                <li><strong>Password Hashing:</strong> Safely storing hashed passwords to enhance security and prevent unauthorized access.</li>
                <li><strong>Blockchain Technology:</strong> Securing transactions and generating unique identifiers in cryptocurrency systems.</li>
                <li><strong>Certificate Generation:</strong> Used in public key infrastructure (PKI) for generating secure certificates.</li>
            </ul>

            <h2>Advantages of SHA-512</h2>
            <p>SHA-512 offers significant advantages over other hashing algorithms:</p>
            <ul>
                <li><strong>High Security:</strong> Resistant to collision attacks, making it ideal for cryptographic applications.</li>
                <li><strong>Fixed-Length Output:</strong> Produces a consistent 512-bit hash, regardless of input size.</li>
                <li><strong>Wide Adoption:</strong> Supported by modern cryptographic protocols, including TLS and SSL.</li>
            </ul>

            <h2>How to Use a SHA-512 Hash Generator?</h2>
            <p>Using a SHA-512 hash generator is straightforward. It can be implemented in various programming languages or accessed via online tools. Here's another example in Python:</p>
            <div className="code-part">
            <code class="language-python">
            import hashlib<br/>

            # Input data<br/>
            data = "Generate SHA-512 Hash"<br/>
            # Create SHA-512 hash<br/>
            sha512 = hashlib.sha512(data.encode()).hexdigest()<br/>

            print(sha512)  # Output: Hash value<br/>
            </code>
            </div>

            <h2>SHA-512 vs SHA-256</h2>
            <p>SHA-512 and SHA-256 are both part of the SHA-2 family, but they differ in hash length and security:</p>
            <ul>
                <li><strong>Hash Length:</strong> SHA-512 produces a 512-bit hash, while SHA-256 generates a 256-bit hash.</li>
                <li><strong>Performance:</strong> SHA-512 is optimized for 64-bit systems, making it faster in certain scenarios.</li>
                <li><strong>Security:</strong> Both algorithms are secure, but SHA-512 offers a larger hash space, making it more resistant to brute-force attacks.</li>
            </ul>

            <h2>Conclusion</h2>
            <p>The SHA-512 hash generator is a robust and secure tool for creating unique hash values. It is widely used in blockchain, digital signatures, and data verification. With its high security and reliability, SHA-512 remains a trusted choice for modern cryptographic applications.</p>
            <p>Interested reading more about SHA-512 Hash? Find this Wiki <a href="https://en.wikipedia.org/wiki/SHA-2">link</a>. </p>
        </div>
        <div className="col-lg-3">
          <div className="border bg-light p-3 text-center vertical-ads">
            {/* Placeholder for Ads */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SHA512HashGenerator;
