import React, { useState } from "react";
import CryptoJS from "crypto-js";

const MD5HashGenerator = () => {
  const [input, setInput] = useState("");
  const [hash, setHash] = useState("");
  const [copySuccess, setCopySuccess] = useState(""); 
  const handleGenerateHash = () => {
    const hashResult = CryptoJS.MD5(input).toString();
    setCopySuccess("");
    setHash(hashResult);
  };

  const handleCopyToClipboard = () => {
    if (hash) {
      navigator.clipboard.writeText(hash).then(
        () => {
          setCopySuccess("Copied to clipboard!");
        },
        (err) => {
          setCopySuccess("Failed to copy.");
        }
      );
    }

  };
  return (
    <div className="container py-5">
      <div className="row">
      <div className="col-lg-3 d-none d-lg-block">
          <div className="border bg-light p-3 text-center vertical-ads">
            {/* Placeholder for Ads */}
          </div>
        </div>
        <div className="col-lg-6">
          <h1>MD5 Hash Generator</h1>
          <textarea
            className="form-control mb-3"
            rows="4"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Enter text to hash"
          ></textarea>
          <button className="btn btn-primary mb-3" onClick={handleGenerateHash}>
            Generate md5 Hash
          </button>
          <div className="position-relative mb-3">
            <textarea
              className="form-control"
              rows="4"
              readOnly
              value={hash}
              placeholder="Hash result will appear here"
              style={{ paddingRight: "40px" }} // Space for the icon
            ></textarea>
            {hash && (
              <button
                className="btn copy-icon"
                onClick={handleCopyToClipboard}
                aria-label="Copy to clipboard"
                style={{
                  position: "absolute",
                  top: "20%",
                  right: "-10px",
                  transform: "translateY(-50%)",
                  backgroundColor: "transparent",
                  border: "none",
                  cursor: "pointer",
                  color: "#007bff",
                  fontSize: "1.2rem",
                }}
              >📋</button>
            )}
          </div>
          {copySuccess && <span className="text-success mt-2">{copySuccess}</span>}


            <h2>What is an MD5 Hash Generator?</h2>
            <p>An MD5 hash generator is a tool or algorithm that generates a 128-bit hash value (commonly represented as a 32-character hexadecimal number) from input data. MD5 (Message-Digest Algorithm 5) is widely used in cryptographic applications to verify the integrity of files and passwords.</p>

            <h2>How Does MD5 Hashing Work?</h2>
            <p>The MD5 algorithm works by taking input data, processing it through a series of mathematical operations, and producing a fixed-length hash. Here's how it works:</p>
            <ol>
                <li><strong>Input Preprocessing:</strong> The input data is padded to ensure its length is a multiple of 512 bits.</li>
                <li><strong>Message Segmentation:</strong> The data is divided into 512-bit blocks for processing.</li>
                <li><strong>Processing Through MD5 Algorithm:</strong> Each block is processed through four nonlinear functions to generate a unique hash.</li>
                <li><strong>Final Output:</strong> The resulting hash is a 128-bit value, typically represented as a 32-character hexadecimal string.</li>
            </ol>

            <h3>Example of MD5 Hashing</h3>
            <p>Here's an example of generating an MD5 hash:</p>
            <div className="code-part">
            <code class="language-python">
            import hashlib<br/>

            # Input string<br/>
            input_data = "Hello, World!"<br/>
            # Generate MD5 hash<br/>
            md5_hash = hashlib.md5(input_data.encode()).hexdigest()<br/>

            print(md5_hash)  # Output: fc3ff98e8c6a0d3087d515c0473f8677<br/>
            </code>
            </div>

            <h2>Applications of MD5 Hash Generator</h2>
            <p>MD5 hash generators are widely used in various scenarios, including:</p>
            <ul>
                <li><strong>Data Integrity Verification:</strong> Ensuring that files or data have not been altered during transmission by comparing hash values.</li>
                <li><strong>Password Storage:</strong> Storing hashed passwords instead of plain text to enhance security (though MD5 is considered less secure for modern use).</li>
                <li><strong>Digital Signatures:</strong> Creating unique identifiers for documents or data.</li>
                <li><strong>Checksums:</strong> Generating checksums to detect errors in data during storage or transfer.</li>
            </ul>

            <h2>Advantages and Limitations of MD5</h2>
            <p>While MD5 is widely used, it comes with its advantages and limitations:</p>
            <ul>
                <li><strong>Advantages:</strong>
                    <ul>
                        <li>Fast and efficient algorithm for generating hashes.</li>
                        <li>Compact hash output makes it suitable for quick comparisons.</li>
                    </ul>
                </li>
                <li><strong>Limitations:</strong>
                    <ul>
                        <li>Susceptible to collision attacks, making it less secure for cryptographic purposes.</li>
                        <li>Not suitable for hashing sensitive information like passwords in modern applications.</li>
                    </ul>
                </li>
            </ul>

            <h2>How to Use an MD5 Hash Generator?</h2>
            <p>Using an MD5 hash generator is straightforward. You can use online tools or implement it in programming languages like Python, Java, or PHP. For example:</p>
            <div className="code-part">
            <code class="language-python">
            import hashlib<br/>

            # Input data<br/>
            data = "Generate MD5 Hash"<br/>
            # Create MD5 hash<br/>
            md5 = hashlib.md5(data.encode()).hexdigest()<br/>

            print(md5)  # Output: Hash value<br/>
            </code>
            </div>

            <h2>Conclusion</h2>
            <p>The MD5 hash generator is a valuable tool for generating quick and efficient hash values for various purposes, including data integrity checks and checksum generation. However, due to its vulnerabilities, it is recommended to use more secure algorithms like SHA-256 for cryptographic purposes.</p>
            <p>Interested reading more about MD5 Hash? Find this Wiki <a href="https://en.wikipedia.org/wiki/MD5">link</a>. </p>

        </div>
        <div className="col-lg-3">
          <div className="border bg-light p-3 text-center vertical-ads">
            {/* Placeholder for Ads */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MD5HashGenerator;
