import React from "react";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <div style={{ minHeight: "150vh" }}>
      {/* Features Section */}
      <section className="py-5">
        <div className="container">
          <h2 className="text-center mb-4">Explore Our Tools</h2>
          <div className="row">
            {/* Encoder / Decoder Tools */}
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="card h-100 shadow-sm">
                <div className="card-body text-center">
                  <h5 className="card-title">Encoders / Decoders</h5>
                  <p className="card-text">
                    <Link to="/md5-hash-generator">MD5 Hash</Link>,{" "}
                    <Link to="/base64-decoder">Base64 Decode</Link>,{" "}
                    <Link to="/base64-encoder">Base64 Encode</Link>,{" "}
                    <Link to="/sha1-hash-generator">SHA-1 Hash Generator</Link>,{" "}
                    <Link to="/sha256-hash-generator">SHA-256 Hash Generator</Link>,{" "}
                    <Link to="/sha512-hash-generator">SHA512 Hash Generator</Link>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="card h-100 shadow-sm">
                <div className="card-body text-center">
                  <h5 className="card-title">Color Mixer Tool</h5>
                  <p className="card-text">
                    Blend two colors, adjust the mix ratio, and generate unique color combinations with hex codes. Perfect for designers and developers.
                  </p>
                  <Link to="/color-mixer" className="btn btn-primary">
                    Try Now
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="card h-100 shadow-sm">
                <div className="card-body text-center">
                  <h5 className="card-title">Color Converter Tool</h5>
                  <p className="card-text">
                    Easily convert colors between HEX and RGB formats. Generate accurate color codes for web and graphic design projects in seconds.
                  </p>
                  <Link to="/color-converter" className="btn btn-primary">
                    Try Now
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="card h-100 shadow-sm">
                <div className="card-body text-center">
                  <h5 className="card-title">.htaccess Generator Tool</h5>
                  <p className="card-text">
                    Create custom .htaccess files effortlessly. Configure SEO-friendly redirects, caching, access control, and error pages for your website.
                  </p>
                  <Link to="/online-htaccess-generator" className="btn btn-primary">
                    Try Now
                  </Link>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>

      {/* Games and Activities Section */}
      <section className="py-5 bg-light">
        <div className="container">
          <h2 className="text-center mb-4">Office/Home Games and Activities</h2>
          <div className="row">
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="card h-100 shadow-sm">
                <div className="card-body text-center">
                  <h5 className="card-title">In or Out Game</h5>
                  <p className="card-text">
                    A fun reflex-based game for all ages! Follow the commands to step "In" or "Out" of the circle. Perfect for office breaks or family fun at home.
                  </p>
                  <Link to="/in-out-game" className="btn btn-primary">
                    Play Now
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
            <div className="card h-100 shadow-sm">
              <div className="card-body text-center">
                <h5 className="card-title">Tic Tac Toe Game</h5>
                <p className="card-text">
                  Enjoy the classic Tic Tac Toe game online! Challenge the computer, enhance your strategic thinking, and have endless fun. Perfect for quick breaks or casual gameplay.
                </p>
                <Link to="/tik-tak-toe" className="btn btn-primary">
                  Play Now
                </Link>
              </div>
            </div>
          </div>
            <div className="col-lg-4 col-md-6 mb-4">
                <div className="card h-100 shadow-sm">
                  <div className="card-body text-center">
                    <h5 className="card-title">Hangman Game</h5>
                    <p className="card-text">
                      Test your vocabulary and general knowledge with the classic Hangman game! Guess the word by selecting letters, and learn while having fun.
                    </p>
                    <Link to="/hangman" className="btn btn-primary">
                      Play Now
                    </Link>
                  </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="card h-100 shadow-sm">
                <div className="card-body text-center">
                  <h5 className="card-title">Snake Game</h5>
                  <p className="card-text">
                    Relive the nostalgia of the classic Snake Game! Guide your snake, eat food, and grow longer while avoiding walls and your tail. Fun for all ages!
                  </p>
                  <Link to="/snake-game" className="btn btn-primary">
                    Play Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
