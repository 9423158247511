import React, { useState } from "react";

const HtaccessGenerator = () => {
  const [redirects, setRedirects] = useState([{ from: "", to: "" }]);
  const [customErrorPage, setCustomErrorPage] = useState("");
  const [enableHttps, setEnableHttps] = useState(false);
  const [blockIp, setBlockIp] = useState("");
  const [enableCaching, setEnableCaching] = useState(false);
  const [enableHotlinkProtection, setEnableHotlinkProtection] = useState(false);
  const [disableDirectoryListing, setDisableDirectoryListing] = useState(false);
  const [passwordProtection, setPasswordProtection] = useState("");
  const [defaultIndex, setDefaultIndex] = useState("");
  const [enableGzip, setEnableGzip] = useState(false);
  const [generatedHtaccess, setGeneratedHtaccess] = useState("");
  const [copySuccess, setCopySuccess] = useState("");

  const handleAddRedirect = () => {
    setRedirects([...redirects, { from: "", to: "" }]);
  };

  const handleRedirectChange = (index, field, value) => {
    const updatedRedirects = [...redirects];
    updatedRedirects[index][field] = value;
    setRedirects(updatedRedirects);
  };

  const handleGenerate = () => {
    let htaccessContent = `
# BEGIN Generated .htaccess
<IfModule mod_rewrite.c>
RewriteEngine On
`;

    if (enableHttps) {
      htaccessContent += `
# Redirect all traffic to HTTPS
RewriteCond %{HTTPS} off
RewriteRule ^ https://%{HTTP_HOST}%{REQUEST_URI} [L,R=301]
`;
    }

    redirects.forEach((redirect) => {
      if (redirect.from && redirect.to) {
        htaccessContent += `
# Redirect ${redirect.from} to ${redirect.to}
RewriteRule ^${redirect.from}$ ${redirect.to} [L,R=301]
`;
      }
    });

    if (blockIp) {
      htaccessContent += `
# Block specific IP address
Deny from ${blockIp}
`;
    }

    if (customErrorPage) {
      htaccessContent += `
# Custom Error Page
ErrorDocument 404 ${customErrorPage}
`;
    }

    if (enableCaching) {
      htaccessContent += `
# Enable caching for static files
<IfModule mod_expires.c>
ExpiresActive On
ExpiresByType image/jpg "access plus 1 month"
ExpiresByType image/jpeg "access plus 1 month"
ExpiresByType image/png "access plus 1 month"
ExpiresByType text/css "access plus 1 week"
ExpiresByType application/javascript "access plus 1 week"
</IfModule>
`;
    }

    if (enableHotlinkProtection) {
      htaccessContent += `
# Prevent Hotlinking
<IfModule mod_rewrite.c>
RewriteCond %{HTTP_REFERER} !^$
RewriteCond %{HTTP_REFERER} !^https?://(www\\.)?yourdomain\\.com [NC]
RewriteRule \\.(jpg|jpeg|png|gif|webp)$ - [F,NC]
</IfModule>
`;
    }

    if (disableDirectoryListing) {
      htaccessContent += `
# Disable Directory Listing
Options -Indexes
`;
    }

    if (passwordProtection) {
      htaccessContent += `
# Password Protection
AuthType Basic
AuthName "Restricted Area"
AuthUserFile ${passwordProtection}
Require valid-user
`;
    }

    if (defaultIndex) {
      htaccessContent += `
# Default Directory Index
DirectoryIndex ${defaultIndex}
`;
    }

    if (enableGzip) {
      htaccessContent += `
# Enable Gzip Compression
<IfModule mod_deflate.c>
AddOutputFilterByType DEFLATE text/html text/css application/javascript
AddOutputFilterByType DEFLATE text/plain text/xml application/xml application/json
</IfModule>
`;
    }

    htaccessContent += `
</IfModule>
# END Generated .htaccess
`;

    setGeneratedHtaccess(htaccessContent);
    setCopySuccess("");
  };

  const handleCopyToClipboard = () => {
    if (generatedHtaccess) {
      navigator.clipboard.writeText(generatedHtaccess).then(
        () => {
          setCopySuccess("Copied to clipboard!");
        },
        () => {
          setCopySuccess("Failed to copy.");
        }
      );
    }
  };

  return (
    <div className="container py-5">
      <div className="row">
        <div className="col-lg-3 d-none d-lg-block">
          <div className="border bg-light p-3 text-center">
            {/* Placeholder for Ads */}
          </div>
        </div>
        <div className="col-lg-6">
          <h1>.htaccess Generator</h1>
          <div className="mb-3">
            <label className="form-label">Redirects</label>
            {redirects.map((redirect, index) => (
              <div key={index} className="d-flex mb-2">
                <input
                  type="text"
                  className="form-control me-2"
                  placeholder="From (e.g., old-page)"
                  value={redirect.from}
                  onChange={(e) =>
                    handleRedirectChange(index, "from", e.target.value)
                  }
                />
                <input
                  type="text"
                  className="form-control me-2"
                  placeholder="To (e.g., /new-page)"
                  value={redirect.to}
                  onChange={(e) =>
                    handleRedirectChange(index, "to", e.target.value)
                  }
                />
              </div>
            ))}
            <button className="btn btn-secondary mb-3" onClick={handleAddRedirect}>
              Add Redirect
            </button>
          </div>

          {/* Other Options */}
          <div className="mb-3 form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="enableHttps"
              checked={enableHttps}
              onChange={(e) => setEnableHttps(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="enableHttps">
              Redirect all traffic to HTTPS
            </label>
          </div>
          <div className="mb-3">
            <label className="form-label">Block an IP Address</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter IP address to block (e.g., 192.168.1.1)"
              value={blockIp}
              onChange={(e) => setBlockIp(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Custom 404 Error Page</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter the path to your custom 404 page (e.g., /404.html)"
              value={customErrorPage}
              onChange={(e) => setCustomErrorPage(e.target.value)}
            />
          </div>
          <div className="mb-3 form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="enableCaching"
              checked={enableCaching}
              onChange={(e) => setEnableCaching(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="enableCaching">
              Enable caching for static files
            </label>
          </div>
          <div className="mb-3 form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="enableHotlinkProtection"
              checked={enableHotlinkProtection}
              onChange={(e) => setEnableHotlinkProtection(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="enableHotlinkProtection">
              Enable Hotlink Protection
            </label>
          </div>
          <div className="mb-3 form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="disableDirectoryListing"
              checked={disableDirectoryListing}
              onChange={(e) => setDisableDirectoryListing(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="disableDirectoryListing">
              Disable Directory Listing
            </label>
          </div>
          <div className="mb-3">
            <label className="form-label">Password Protection</label>
            <input
              type="text"
              className="form-control"
              placeholder="Path to .htpasswd file (e.g., /path/to/.htpasswd)"
              value={passwordProtection}
              onChange={(e) => setPasswordProtection(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Default Directory Index</label>
            <input
              type="text"
              className="form-control"
              placeholder="Default index file (e.g., index.html)"
              value={defaultIndex}
              onChange={(e) => setDefaultIndex(e.target.value)}
            />
          </div>
          <div className="mb-3 form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="enableGzip"
              checked={enableGzip}
              onChange={(e) => setEnableGzip(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="enableGzip">
              Enable Gzip Compression
            </label>
          </div>

          <button className="btn btn-primary mb-3" onClick={handleGenerate}>
            Generate .htaccess
          </button>

          <div className="position-relative mb-3">
            <textarea
              className="form-control"
              rows="8"
              readOnly
              value={generatedHtaccess}
              placeholder="Your .htaccess file will appear here"
            ></textarea>
            {generatedHtaccess && (
              <button
                className="btn copy-icon"
                onClick={handleCopyToClipboard}
                aria-label="Copy to clipboard"
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  backgroundColor: "transparent",
                  border: "none",
                  cursor: "pointer",
                  color: "#007bff",
                  fontSize: "1.2rem",
                }}
              >
                📋
              </button>
            )}
          </div>

          {copySuccess && <span className="text-success mt-2">{copySuccess}</span>}

          <h2>What is an .htaccess File?</h2>
<p>
  The <code>.htaccess</code> file is a configuration file used by Apache servers to manage server settings. 
  It allows website administrators to control URL redirects, access permissions, and various other server configurations at the directory level.
</p>

<h2>Why Use .htaccess?</h2>
<p>
  Using <code>.htaccess</code> enables webmasters to achieve several critical functions without modifying the core server configuration. 
  It provides:
</p>
<ul>
  <li><strong>SEO Benefits:</strong> Redirects and clean URLs improve search engine rankings.</li>
  <li><strong>Access Control:</strong> Protect specific directories or files.</li>
  <li><strong>Error Management:</strong> Customize error pages (e.g., 404, 403).</li>
  <li><strong>Performance Optimization:</strong> Cache static resources, enable Gzip compression, and set expiration headers.</li>
  <li><strong>Security Enhancements:</strong> Restrict access to your website using IP blocking, password protection, or hotlink prevention.</li>
</ul>

<h2>Popular .htaccess Directives</h2>
<p>Here are some of the most commonly used directives in <code>.htaccess</code>:</p>
<ul>
  <li>
    <strong>Redirects:</strong> Create 301 or 302 redirects for outdated or changed URLs. 
    Example:
    <code>Redirect 301 /old-page.html /new-page.html</code>
  </li>
  <li>
    <strong>URL Rewriting:</strong> Use the <code>mod_rewrite</code> module to create clean and user-friendly URLs. 
    Example:
    <code>RewriteRule ^about-us$ about.html [L]</code>
  </li>
  <li>
    <strong>Access Control:</strong> Restrict access to specific IPs or users. 
    Example:
    <code>Deny from 192.168.1.1</code>
  </li>
  <li>
    <strong>Custom Error Pages:</strong> Redirect users to a custom error page when errors occur. 
    Example:
    <code>ErrorDocument 404 /custom-404.html</code>
  </li>
  <li>
    <strong>Cache Control:</strong> Set caching rules for static files. 
    Example:
    <code>
      &lt;IfModule mod_expires.c&gt;
      ExpiresActive On
      ExpiresByType image/jpg "access plus 1 month"
      &lt;/IfModule&gt;
    </code>
  </li>
  <li>
    <strong>Gzip Compression:</strong> Compress files for faster loading times. 
    Example:
    <code>
      &lt;IfModule mod_deflate.c&gt;
      AddOutputFilterByType DEFLATE text/html text/css application/javascript
      &lt;/IfModule&gt;
    </code>
  </li>
  <li>
    <strong>Hotlink Protection:</strong> Prevent unauthorized use of your assets like images or videos on other websites. 
    Example:
    <code>
      &lt;IfModule mod_rewrite.c&gt;
      RewriteCond %&#123;HTTP_REFERER&#125; !^https?://(www\\.)?yourdomain\\.com [NC]
      RewriteRule \\.(jpg|png|gif)$ - [F,NC]
      &lt;/IfModule&gt;
    </code>
  </li>
  <li>
    <strong>Password Protection:</strong> Protect a directory with a password. 
    Example:
    <code>
      AuthType Basic
      AuthName "Restricted Area"
      AuthUserFile /path/to/.htpasswd
      Require valid-user
    </code>
  </li>
  <li>
    <strong>Default Directory Index:</strong> Specify the default file to serve when accessing a directory. 
    Example:
    <code>DirectoryIndex index.html</code>
  </li>
</ul>

<h3>Applications of .htaccess</h3>
<p>
  The <code>.htaccess</code> file is used for:
</p>
<ul>
  <li>Enforcing HTTPS to secure your website.</li>
  <li>Blocking IP addresses or user agents to enhance security.</li>
  <li>Optimizing website speed through Gzip compression and caching.</li>
  <li>Preventing hotlinking to protect your assets.</li>
  <li>Setting up password-protected areas for restricted access.</li>
  <li>Customizing error pages to improve user experience.</li>
</ul>

<h3>How to Use the Generated .htaccess File</h3>
<p>
  After generating the file, follow these steps:
</p>
<ol>
  <li>Download the generated <code>.htaccess</code> file.</li>
  <li>Upload it to the root directory of your website (or the directory where the rules should apply).</li>
  <li>Test the functionality by visiting your website.</li>
</ol>

<h3>Key Benefits of .htaccess</h3>
<ul>
  <li><strong>Improved User Experience:</strong> Clean URLs and custom error pages enhance usability.</li>
  <li><strong>Better Indexing:</strong> SEO-friendly redirects and optimized content improve search engine rankings.</li>
  <li><strong>Faster Loading:</strong> Gzip compression and caching significantly reduce page load times.</li>
  <li><strong>Enhanced Security:</strong> Protect your website with IP blocking, hotlink prevention, and password protection.</li>
</ul>

<h3>Conclusion</h3>
<p>
  The <code>.htaccess</code> file is a versatile and powerful tool for managing server configurations. By using the generator above, you can easily create custom .htaccess rules for various purposes, including SEO, security, performance optimization, and access control. Explore the possibilities and take control of your website's configuration!
</p>
<p>
  Read more about <code>.htaccess</code> on this <a href="https://httpd.apache.org/docs/current/howto/htaccess.html">Apache Documentation</a>.
</p>

        </div>
        <div className="col-lg-3 d-none d-lg-block">
          <div className="border bg-light p-3 text-center">
            {/* Placeholder for Ads */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HtaccessGenerator;
