import React from "react";

const AboutPage = () => {
  return (
    <>
      <section className="about-us">
        <div className="container py-5">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-6">
              <h1 className="about-title">About Us</h1>
              <p>
                Welcome to <strong>Help Daily App</strong>, your one-stop platform for quick access to handy tools and utilities designed to assist developers, professionals, and enthusiasts in their daily workflows. Whether you need to encode, decode, hash, or perform other essential calculations, Help Daily App provides a suite of easy-to-use tools to save you time and effort.
              </p>

              <p>
                <strong>Help Daily App</strong> is the brainchild of <strong>Umair ul Haq Gill</strong>, a seasoned software engineer with years of experience serving multinational corporations and government organizations as an I.T. technical professional. His journey in the tech industry has been marked by innovation, expertise, and a passion for simplifying complex tasks.
              </p>

              <p>
                Today, Umair leads <strong>Maholaat Limited</strong>, a United Kingdom-based software house committed to delivering cutting-edge software solutions. Maholaat Limited, the parent company of Help Daily App, is a trusted name in the software development industry, known for creating reliable, efficient, and user-friendly applications. Learn more about Maholaat Limited by visiting our parent site:
                &nbsp;<a href="https://maholaat.com" target="_blank" rel="noopener noreferrer">maholaat.com</a>.
              </p>

              <p>
                At <strong>Help Daily App</strong>, we aim to empower developers and professionals with tools that are not only powerful but also accessible. Our platform is constantly evolving, with new tools and features added regularly based on user feedback and emerging needs.
              </p>

              <p>
                Thank you for choosing <strong>Help Daily App</strong> as your trusted resource. We’re here to make your tasks easier, faster, and more efficient.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutPage;
