import React, { useState } from "react";

const URLDecoder = () => {
  const [input, setInput] = useState("");
  const [output, setOutput] = useState("");
  const [copySuccess, setCopySuccess] = useState(""); 
  const handleDecode = () => {
    try {
      setOutput(decodeURIComponent(input));
      setCopySuccess("");
    } catch (error) {
      setOutput("Invalid URL encoding.");
    }
  };

  const handleCopyToClipboard = () => {
    if (output) {
      navigator.clipboard.writeText(output).then(
        () => {
          setCopySuccess("Copied to clipboard!");
        },
        (err) => {
          setCopySuccess("Failed to copy.");
        }
      );
    }

  };
  return (
    <div className="container py-5">
      <div className="row">
        <div className="col-lg-3 d-none d-lg-block">
          <div className="border bg-light p-3 text-center vertical-ads">
            {/* Placeholder for Ads */}
          </div>
        </div>
        <div className="col-lg-6">
          <h1>URL Decoder</h1>
          <textarea
            className="form-control mb-3"
            rows="4"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Enter encoded URL"
          ></textarea>
          <button className="btn btn-primary mb-3" onClick={handleDecode}>
            URL Decode
          </button>
          <div className="position-relative mb-3">
            <textarea
              className="form-control"
              rows="4"
              readOnly
              value={output}
              placeholder="Decoded result will appear here"
              style={{ paddingRight: "40px" }} // Space for the icon
            ></textarea>
            {output && (
              <button
                className="btn copy-icon"
                onClick={handleCopyToClipboard}
                aria-label="Copy to clipboard"
                style={{
                  position: "absolute",
                  top: "20%",
                  right: "-10px",
                  transform: "translateY(-50%)",
                  backgroundColor: "transparent",
                  border: "none",
                  cursor: "pointer",
                  color: "#007bff",
                  fontSize: "1.2rem",
                }}
              >📋</button>
            )}
          </div>
          {copySuccess && <span className="text-success mt-2">{copySuccess}</span>}

            <h2>What is a URL Decoder?</h2>
            <p>A URL decoder is a tool or algorithm used to decode encoded characters in a URL string. When URLs contain special characters, they are encoded to ensure they can be safely transmitted over the internet. URL decoding reverses this process, restoring the original format of the URL.</p>

            <h2>Why is URL Decoding Important?</h2>
            <p>URL decoding is essential for web developers and internet users because URLs often contain special characters that are not allowed in their raw form. Encoding ensures compatibility across systems, while decoding is necessary to interpret and use the data as intended.</p>

            <h3>How Does URL Decoding Work?</h3>
            <p>URL decoding works by identifying percent-encoded characters in a URL and converting them back to their original representation. Here's how it works:</p>
            <ol>
                <li><strong>Identify Encoded Characters:</strong> Encoded characters are represented by a <code>%</code> symbol followed by two hexadecimal digits (e.g., <code>%20</code> for a space).</li>
                <li><strong>Convert to Original Characters:</strong> Each encoded character is translated into its ASCII equivalent.</li>
                <li><strong>Reconstruct the URL:</strong> The decoded characters are combined to form the original URL or string.</li>
            </ol>
            <p>For example, the encoded URL <code>https%3A%2F%2Fexample.com%2Fhello%20world</code> decodes to <code>https://example.com/hello world</code>.</p>

            <h2>Applications of URL Decoder</h2>
            <p>URL decoders are widely used in various scenarios, including:</p>
            <ul>
                <li><strong>Web Development:</strong> Decoding query strings or parameters in URLs for processing.</li>
                <li><strong>API Integration:</strong> Parsing and decoding data sent via HTTP requests in encoded form.</li>
                <li><strong>Debugging:</strong> Understanding and troubleshooting encoded URLs during web development.</li>
                <li><strong>Data Transformation:</strong> Converting encoded strings into readable text for analysis or storage.</li>
            </ul>

            <h3>How to Perform URL Decoding?</h3>
            <p>URL decoding can be performed using various programming languages or online tools. Here's an example in Python:</p>
            <div className="code-part">
            <code class="language-python">
            import urllib.parse

            # Encoded URL
            encoded_url = "https%3A%2F%2Fexample.com%2Fhello%20world"
            # Decode URL
            decoded_url = urllib.parse.unquote(encoded_url)

            print(decoded_url)  # Output: https://example.com/hello world
            </code>
            </div>

            <h2>Common URL Encoding Patterns</h2>
            <p>Here are some commonly encoded characters and their decoded equivalents:</p>
            <table>
                <thead>
                    <tr>
                        <th>Encoded Character</th>
                        <th>Decoded Character</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>%20</td>
                        <td>Space</td>
                    </tr>
                    <tr>
                        <td>%3A</td>
                        <td>:</td>
                    </tr>
                    <tr>
                        <td>%2F</td>
                        <td>/</td>
                    </tr>
                    <tr>
                        <td>%3F</td>
                        <td>?</td>
                    </tr>
                    <tr>
                        <td>%40</td>
                        <td>@</td>
                    </tr>
                    <tr>
                        <td>%23</td>
                        <td>#</td>
                    </tr>
                </tbody>
            </table>

            <h2>Advantages of Using URL Decoders</h2>
            <p>URL decoders provide several benefits:</p>
            <ul>
                <li><strong>Improved Readability:</strong> Translates encoded URLs into human-readable formats.</li>
                <li><strong>Seamless API Communication:</strong> Decodes data passed via URLs for proper processing in APIs.</li>
                <li><strong>Enhanced Debugging:</strong> Simplifies the process of troubleshooting encoded URL strings.</li>
            </ul>

            <h2>Conclusion</h2>
            <p>URL decoders are indispensable tools for web developers, API integrators, and anyone dealing with URLs. By converting encoded characters back to their original form, URL decoders enable smooth data transmission, debugging, and processing. Whether you’re working with web applications or troubleshooting APIs, understanding URL decoding is essential for effective development.</p>
            <p>Interested reading more about URL Decode? Find this PHP <a href="https://www.php.net/manual/en/function.urldecode.php">link</a>. </p>
        </div>
        <div className="col-lg-3">
          <div className="border bg-light p-3 text-center vertical-ads">
            {/* Placeholder for Ads */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default URLDecoder;
