import React, { useState, useEffect } from "react";

const SnakeGame = () => {
  const [snake, setSnake] = useState([{ x: 10, y: 10 }]);
  const [food, setFood] = useState({ x: 15, y: 15 });
  const [direction, setDirection] = useState({ x: 1, y: 0 }); // Starts moving right
  const [gameOver, setGameOver] = useState(false);
  const [score, setScore] = useState(0);
  const [highScore, setHighScore] = useState(0);
  const [currentSpeed, setCurrentSpeed] = useState(500); // Initial speed
  const [isGameStarted, setIsGameStarted] = useState(false); // New state

  const gridSize = 20;

  const moveSnake = () => {
    const newSnake = [...snake];
    const head = { ...newSnake[newSnake.length - 1] };

    // Move the head
    head.x += direction.x;
    head.y += direction.y;

    // Check for collision with walls
    if (head.x < 0 || head.x >= gridSize || head.y < 0 || head.y >= gridSize) {
      setGameOver(true);
      setIsGameStarted(false);
      return;
    }

    // Check for collision with itself
    if (
      newSnake.some(
        (segment, idx) =>
          idx !== newSnake.length - 1 && segment.x === head.x && segment.y === head.y
      )
    ) {
      setGameOver(true);
      setIsGameStarted(false);
      return;
    }

    // Add new head to the snake
    newSnake.push(head);

    // Check if the snake eats the food
    if (head.x === food.x && head.y === food.y) {
      setFood({
        x: Math.floor(Math.random() * gridSize),
        y: Math.floor(Math.random() * gridSize),
      });
      setScore(score + 10);

      // Reduce speed by 1 ms on eating food
      setCurrentSpeed((prevSpeed) => Math.max(prevSpeed - 1, 50)); // Ensure minimum speed
    } else {
      // Remove the tail unless food is eaten
      newSnake.shift();
    }

    setSnake(newSnake);
  };

  const handleKeyDown = (e) => {
    // Prevent default behavior for arrow keys to stop scrolling
    if (["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"].includes(e.key)) {
      e.preventDefault();
    }

    if (!isGameStarted) setIsGameStarted(true); // Start the game on first key press
    if (gameOver) return;

    switch (e.key) {
      case "ArrowUp":
        if (direction.y === 0) setDirection({ x: 0, y: -1 });
        break;
      case "ArrowDown":
        if (direction.y === 0) setDirection({ x: 0, y: 1 });
        break;
      case "ArrowLeft":
        if (direction.x === 0) setDirection({ x: -1, y: 0 });
        break;
      case "ArrowRight":
        if (direction.x === 0) setDirection({ x: 1, y: 0 });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);

    // Snake auto-movement
    const interval = setInterval(() => {
      if (!gameOver && isGameStarted) moveSnake();
    }, currentSpeed);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      clearInterval(interval);
    };
  }, [direction, gameOver, snake, currentSpeed, isGameStarted]);

  const resetGame = () => {
    setSnake([{ x: 10, y: 10 }]);
    setFood({ x: 15, y: 15 });
    setDirection({ x: 1, y: 0 }); // Reset direction to right
    setGameOver(false);
    setIsGameStarted(false); // Reset game started state
    if (score > highScore) setHighScore(score);
    setScore(0);
    setCurrentSpeed(500); // Reset speed
  };

  return (
    <div className="container py-5">
      <div className="row">
        <div className="col-lg-3 d-none d-lg-block">
          <div className="border bg-light p-3 text-center vertical-ads">
            {/* Placeholder for Ads */}
          </div>
        </div>
        <div className="col-lg-6">
          <h1>Snake Game: Classic Arcade Adventure</h1>
          <p>
            Navigate your snake automatically as it keeps moving forward. Change
            direction using arrow keys or click on the game board to guide your
            snake. Eat food, grow longer, and avoid collisions to set a high
            score!
          </p>

          <div
            className="game-board"
            style={{
              position: "relative",
              width: 400,
              height: 400,
              border: "1px solid black",
              margin: "20px auto",
            }}
          >
            {snake.map((segment, index) => (
              <div
                key={index}
                style={{
                  position: "absolute",
                  width: 20,
                  height: 20,
                  backgroundColor: "green",
                  left: segment.x * 20,
                  top: segment.y * 20,
                }}
              ></div>
            ))}
            <div
              style={{
                position: "absolute",
                width: 20,
                height: 20,
                backgroundColor: "red",
                left: food.x * 20,
                top: food.y * 20,
              }}
            ></div>
          </div>

          <div className="text-center">
            <h3>Score: {score}</h3>
            <h3>High Score: {highScore}</h3>
          </div>

          {gameOver && (
            <div className="text-center">
              <h2 className="text-danger">Game Over!</h2>
              <button className="btn btn-primary" onClick={resetGame}>
                Restart Game
              </button>
            </div>
          )}

            <h2>What Makes the Snake Game Addictive?</h2>
          <p>
            The <strong>Snake Game</strong> is simple yet challenging. It’s a perfect game for people of all ages. The endless pursuit of food and the thrill of seeing your snake grow longer keeps players hooked for hours!
          </p>

          <h3>Pro Tips for Mastering the Snake Game</h3>
          <ol>
            <li>Start slow and focus on controlling the snake's movement.</li>
            <li>Plan your moves ahead to avoid trapping yourself.</li>
            <li>Stay close to the center of the board for maximum flexibility.</li>
            <li>Practice patience; rushing often leads to collisions!</li>
          </ol>

          <h3>Why Play the Snake Game?</h3>
          <ul>
            <li>Boost your focus and reaction time.</li>
            <li>Relive the nostalgic charm of classic arcade games.</li>
            <li>Compete with friends and family to set new high scores!</li>
          </ul>

          <h3>Future Enhancements</h3>
          <p>
            Stay tuned for updates with new features such as power-ups, different game modes, and customizable themes to enhance your gaming experience.
          </p>
        </div>
        <div className="col-lg-3 d-none d-lg-block">
          <div className="border bg-light p-3 text-center vertical-ads">
            {/* Placeholder for Ads */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SnakeGame;
