import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "./../logo.png";

const Navbar = () => {
  const [isFixed, setIsFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsFixed(window.scrollY >= window.innerHeight / 2);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <nav
      className={`navbar navbar-expand-lg navbar-dark shadow-sm ${
        isFixed ? "fixed-top" : ""
      }`}
      style={{
        background: "linear-gradient(90deg, #EEBBD5, #2F284E)",
      }}
    >
      <div className="container">
        <Link className="navbar-brand d-flex align-items-center" to="/">
          <img src={logo} alt="Logo" height="50" className="me-2" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ms-auto">
            {/* Encoders/Decoders Menu */}
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="encodersDecodersDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Encoders/Decoders
              </a>
              <ul
                className="dropdown-menu"
                aria-labelledby="encodersDecodersDropdown"
              >
                <li>
                  <Link className="dropdown-item" to="/base64-decoder">
                    Base64 Decode
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/base64-encoder">
                    Base64 Encode
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/md5-hash-generator">
                    MD5 Hash Generator
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/sha1-hash-generator">
                    SHA-1 Hash Generator
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/sha256-hash-generator">
                    SHA-256 Hash Generator
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/sha512-hash-generator">
                    SHA-512 Hash Generator
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/url-encoder">
                    URL Encoder
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/url-decoder">
                    URL Decoder
                  </Link>
                </li>
              </ul>
            </li>

            {/* Fun Activities Menu */}
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="funActivitiesDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Fun Activities
              </a>
              <ul
                className="dropdown-menu"
                aria-labelledby="funActivitiesDropdown"
              >
                <li>
                  <Link className="dropdown-item" to="/in-out-game">
                    In or Out Game
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/tik-tak-toe">
                    Tik Tak Toe
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/hangman">
                    HangMan
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/snake-game">
                    Snake Game
                  </Link>
                </li>

              </ul>
            </li>
            
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="wedDevelopmentDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Web Development Tools
              </a>
              <ul
                className="dropdown-menu"
                aria-labelledby="wedDevelopmentDropdown"
              >
                <li>
                  <Link className="dropdown-item" to="/color-mixer">
                    Online Color Mixer
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/color-converter">
                    Color Converter and Mixer Tool
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/online-htaccess-generator">
                    Online .htaccess Generator Tool
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
