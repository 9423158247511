import React, { useState, useEffect } from "react";

// 100 General Knowledge Questions and Answers
const questions = [
    { question: "The largest planet in our solar system?", answer: "JUPITER" },
    { question: "The chemical symbol for water?", answer: "H2O" },
    { question: "The capital of France?", answer: "PARIS" },
    { question: "The smallest prime number?", answer: "2" },
    { question: "The world's longest river?", answer: "NILE" },
    { question: "What is the largest mammal on Earth?", answer: "BLUE-WHALE" },
    { question: "What is the currency of Japan?", answer: "YEN" },
    { question: "Who painted the Mona Lisa?", answer: "LEONARDO-DA-VINCI" },
    { question: "Which element has the chemical symbol 'O'?", answer: "OXYGEN" },
    { question: "Which country is known as the Land of the Rising Sun?", answer: "JAPAN" },
    { question: "What is the fastest land animal?", answer: "CHEETAH" },
    { question: "Which gas is most abundant in Earth's atmosphere?", answer: "NITROGEN" },
    { question: "Who wrote 'Romeo and Juliet'?", answer: "SHAKESPEARE" },
    { question: "Which planet is closest to the Sun?", answer: "MERCURY" },
    { question: "What is the capital city of Australia?", answer: "CANBERRA" },
    { question: "How many continents are there on Earth?", answer: "SEVEN" },
    { question: "What is the smallest country in the world?", answer: "VATICAN-CITY" },
    { question: "Which ocean is the largest?", answer: "PACIFIC" },
    { question: "What is the name of the longest mountain range in the world?", answer: "ANDES" },
    { question: "Who discovered penicillin?", answer: "ALEXANDER-FLEMING" },
    { question: "Which is the hardest natural substance?", answer: "DIAMOND" },
    { question: "What is the main ingredient in guacamole?", answer: "AVOCADO" },
    { question: "Which planet is known as the Red Planet?", answer: "MARS" },
    { question: "How many bones are there in the human body?", answer: "206" },
    { question: "What is the chemical symbol for gold?", answer: "AU" },
    { question: "What is the tallest mountain in the world?", answer: "EVEREST" },
    { question: "Which planet is known as the Morning Star?", answer: "VENUS" },
    { question: "What is the name of the process by which plants make their food?", answer: "PHOTOSYNTHESIS" },
    { question: "Who is the author of the Harry Potter series?", answer: "JK-ROWLING" },
    { question: "What is the chemical symbol for sodium?", answer: "NA" },
    { question: "Which country gifted the Statue of Liberty to the United States?", answer: "FRANCE" },
    { question: "What is the most spoken language in the world?", answer: "MANDARIN" },
    { question: "Which instrument is used to measure temperature?", answer: "THERMOMETER" },
    { question: "What is the capital city of Canada?", answer: "OTTAWA" },
    { question: "Which continent is known as the 'Dark Continent'?", answer: "AFRICA" },
    { question: "What is the hardest known metal?", answer: "TUNGSTEN" },
    { question: "Who invented the telephone?", answer: "ALEXANDER-GRAHAM-BELL" },
    { question: "What is the freezing point of water in Celsius?", answer: "0" },
    { question: "Which country is famous for the maple leaf?", answer: "CANADA" },
    { question: "What is the longest bone in the human body?", answer: "FEMUR" },
    { question: "Which desert is the largest in the world?", answer: "SAHARA" },
    { question: "What is the square root of 144?", answer: "12" },
    { question: "Which bird is known as the symbol of peace?", answer: "DOVE" },
    { question: "Who was the first President of the United States?", answer: "GEORGE-WASHINGTON" },
    { question: "Which sea is the saltiest in the world?", answer: "DEAD-SEA" },
    { question: "Which animal is known as the King of the Jungle?", answer: "LION" },
    { question: "What is the main ingredient in chocolate?", answer: "COCOA" },
    { question: "What is the capital of Italy?", answer: "ROME" },
    { question: "Which organ is responsible for pumping blood in the human body?", answer: "HEART" },
    { question: "What is the boiling point of water in Celsius?", answer: "100" },
    { question: "Who painted the ceiling of the Sistine Chapel?", answer: "MICHELANGELO" },
    { question: "Which planet is known for its rings?", answer: "SATURN" },
    { question: "What is the hardest part of the human body?", answer: "TOOTH-ENAMEL" },
    { question: "Which bird can mimic human speech?", answer: "PARROT" },
    { question: "What is the currency of the United Kingdom?", answer: "POUND" },
    { question: "Who discovered gravity?", answer: "ISAAC-NEWTON" },
    { question: "What is the capital of India?", answer: "NEW-DELHI" },
    { question: "Which animal is known to have black and white stripes?", answer: "ZEBRA" },
    { question: "What is the process of converting liquid into vapor called?", answer: "EVAPORATION" },
    { question: "Which is the only mammal capable of flight?", answer: "BAT" },
    { question: "What does DNA stand for?", answer: "DEOXYRIBONUCLEIC-ACID" },
    { question: "Which gas do plants absorb during photosynthesis?", answer: "CARBON-DIOXIDE" },
    { question: "What is the smallest unit of life?", answer: "CELL" },
    { question: "Who was the first person to walk on the moon?", answer: "NEIL-ARMSTRONG" },
    { question: "What is the tallest waterfall in the world?", answer: "ANGEL-FALLS" },
    { question: "Which continent has the most countries?", answer: "AFRICA" },
    { question: "What is the name of the fairy in Peter Pan?", answer: "TINKER-BELL" },
    { question: "Which country is known for the Great Wall?", answer: "CHINA" },
    { question: "What is the speed of light?", answer: "299792458 M/S" },
    { question: "What is the heaviest naturally occurring element?", answer: "URANIUM" },
    { question: "Which is the largest internal organ in the human body?", answer: "LIVER" },
    { question: "What is the name of the first artificial satellite launched into space?", answer: "SPUTNIK" },
    { question: "Which ocean is located between Africa and Australia?", answer: "INDIAN-OCEAN" },
    { question: "Which is the coldest place on Earth?", answer: "ANTARCTICA" },
    { question: "What is the square of 15?", answer: "225" },
    { question: "What does HTTP stand for?", answer: "HYPERTEXT-TRANSFER-PROTOCOL" },
    { question: "Which planet is known as the Ice Giant?", answer: "NEPTUNE" },
    { question: "Who wrote 'Pride and Prejudice'?", answer: "JANE-AUSTEN" },
    { question: "What is the primary source of energy for the Earth?", answer: "SUN" },
    { question: "Which festival is known as the Festival of Lights?", answer: "DIWALI" },
    { question: "What is the most abundant metal in the Earth's crust?", answer: "ALUMINUM" },
    { question: "What is the study of stars and planets called?", answer: "ASTRONOMY" },
    { question: "Which part of the plant conducts photosynthesis?", answer: "LEAVES" },
    { question: "What is the national flower of Japan?", answer: "CHERRY-BLOSSOM" }
];

const Hangman = () => {
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [guesses, setGuesses] = useState([]);
    const [incorrectGuesses, setIncorrectGuesses] = useState(0);
    const [maxIncorrectGuesses] = useState(6); // Number of attempts allowed
    const [gameOver, setGameOver] = useState(false);
    const [gameWin, setGameWin] = useState(false);
  
    const currentQuestion = questions[currentQuestionIndex];
    const answer = currentQuestion.answer;
  
    const resetGame = () => {
      setGuesses([]);
      setIncorrectGuesses(0);
      setGameOver(false);
      setGameWin(false);
    };
  
    useEffect(() => {
      const isWin = answer.split("").every((letter) =>
        guesses.includes(letter.toUpperCase())
      );
      if (isWin) {
        setGameWin(true);
        setGameOver(true);
      }
      if (incorrectGuesses >= maxIncorrectGuesses) {
        setGameOver(true);
      }
    }, [guesses, incorrectGuesses]);
  
    const handleGuess = (letter) => {
      if (!gameOver) {
        if (!guesses.includes(letter)) {
          setGuesses([...guesses, letter]);
  
          if (!answer.includes(letter)) {
            setIncorrectGuesses(incorrectGuesses + 1);
          }
        }
      }
    };
  
    const displayWord = () =>
      answer
        .split("")
        .map((letter) =>
          guesses.includes(letter.toUpperCase()) ? letter : "_"
        )
        .join(" ");
  
    const nextQuestion = () => {
      if (currentQuestionIndex < questions.length - 1) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
        resetGame();
      } else {
        alert("You've completed all questions!");
      }
    };
  
    return (
      <div className="container py-5">
        <div className="row">
          <div className="col-lg-3 d-none d-lg-block">
            <div className="border bg-light p-3 text-center vertical-ads">
              {/* Placeholder for Ads */}
            </div>
          </div>
          <div className="col-lg-6">
            <h1>Hangman: General Knowledge Edition</h1>
            <p>
              The <strong>Hangman Game</strong> is an exciting way to test your
              knowledge and reflexes! Answer general knowledge questions by
              guessing the letters of the answer, but be careful—too many wrong
              guesses, and it's game over!
            </p>
  
            {/* Game Content */}
            <p className="mt-4">
              <strong>Question:</strong> {currentQuestion.question}
            </p>
            <p className="word-display mb-4">
              <strong>{displayWord()}</strong>
            </p>
            <p>
              <strong>Incorrect Guesses:</strong> {incorrectGuesses} /{" "}
              {maxIncorrectGuesses}
            </p>
  
            <div className="letters mt-4">
              {"1234567890-ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("").map((letter) => (
                <button
                  key={letter}
                  onClick={() => handleGuess(letter)}
                  className="btn btn-secondary m-1"
                  disabled={guesses.includes(letter) || gameOver}
                >
                  {letter}
                </button>
              ))}
            </div>
  
            {gameOver && (
              <div className="mt-4">
                {gameWin ? (
                  <h2 className="text-success">You Win!</h2>
                ) : (
                  <h2 className="text-danger">
                    Game Over! The correct answer was <strong>{answer}</strong>.
                  </h2>
                )}
                <button className="btn btn-primary mt-3" onClick={nextQuestion}>
                  {currentQuestionIndex < questions.length - 1
                    ? "Next Question"
                    : "Restart"}
                </button>
              </div>
            )}
  
            {/* SEO Content */}
            <h2>About the Hangman Game</h2>
            <p>
              Hangman is a classic guessing game where players try to uncover a
              hidden word one letter at a time. The <strong>General Knowledge Hangman Game</strong> adds a
              twist by presenting players with fun trivia questions to solve.
            </p>
  
            <h3>How to Play</h3>
            <ol>
              <li>Read the general knowledge question.</li>
              <li>Guess the letters of the answer one at a time.</li>
              <li>
                Be careful—each incorrect guess brings you closer to the limit!
              </li>
              <li>
                Complete the word before reaching the maximum number of incorrect
                guesses.
              </li>
            </ol>
  
            <h3>Why Play Hangman?</h3>
            <ul>
              <li>Test your knowledge in a fun and engaging way.</li>
              <li>
                Improve your vocabulary and learn new facts with every question.
              </li>
              <li>
                Challenge yourself to solve the word with minimal incorrect
                guesses.
              </li>
            </ul>
  
            <h3>Applications</h3>
            <ul>
              <li>
                Use Hangman as a fun learning tool for kids and adults alike.
              </li>
              <li>
                Engage friends and family in friendly trivia-based challenges.
              </li>
              <li>
                Enhance cognitive skills like problem-solving and quick thinking.
              </li>
            </ul>
  
            <h2>Conclusion</h2>
            <p>
              Whether you're looking to challenge your general knowledge, have
              fun, or improve your vocabulary, the <strong>Hangman Game</strong> is perfect
              for you. Start playing now and see how many questions you can
              answer correctly!
            </p>
          </div>
          <div className="col-lg-3 d-none d-lg-block">
            <div className="border bg-light p-3 text-center vertical-ads">
              {/* Placeholder for Ads */}
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  export default Hangman;