import React, { useState } from "react";

const ColorConverter = () => {
  const [hexInput, setHexInput] = useState("");
  const [r, setR] = useState("");
  const [g, setG] = useState("");
  const [b, setB] = useState("");
  const [outputHex, setOutputHex] = useState("");
  const [outputRgb, setOutputRgb] = useState("");
  const [copySuccess, setCopySuccess] = useState("");

  const hexToRgb = (hex) => {
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);
    const regex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;
    const result = regex.exec(hex);
    return result
      ? `rgb(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(
          result[3],
          16
        )})`
      : "Invalid HEX code.";
  };

  const rgbToHex = (r, g, b) => {
    const toHex = (n) => {
      const hex = parseInt(n).toString(16);
      return hex.length === 1 ? "0" + hex : hex;
    };
    if (
      !isNaN(r) &&
      !isNaN(g) &&
      !isNaN(b) &&
      r >= 0 &&
      r <= 255 &&
      g >= 0 &&
      g <= 255 &&
      b >= 0 &&
      b <= 255
    ) {
      return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
    }
    return "Invalid RGB values.";
  };

  const handleHexToRgb = () => {
    const resultRgb = hexToRgb(hexInput);
    setOutputRgb(resultRgb);
    setOutputHex(hexInput);
    setCopySuccess("");
  };

  const handleRgbToHex = () => {
    const resultHex = rgbToHex(r, g, b);
    setOutputHex(resultHex);
    setOutputRgb(`rgb(${r}, ${g}, ${b})`);
    setCopySuccess("");
  };

  const handleCopyToClipboard = (value) => {
    if (value) {
      navigator.clipboard.writeText(value).then(
        () => {
          setCopySuccess(`Copied: ${value}`);
        },
        () => {
          setCopySuccess("Failed to copy.");
        }
      );
    }
  };

  return (
    <div className="container py-5">
      <div className="row">
        <div className="col-lg-3 d-none d-lg-block">
          <div className="border bg-light p-3 text-center vertical-ads">
            {/* Placeholder for Ads */}
          </div>
        </div>
        <div className="col-lg-6">
          <h1>Color Converter</h1>
          <textarea
            className="form-control mb-3"
            rows="2"
            value={hexInput}
            onChange={(e) => setHexInput(e.target.value)}
            placeholder="Enter HEX color code (e.g., #FFFFFF)"
          ></textarea>
          <button className="btn btn-primary mb-3" onClick={handleHexToRgb}>
            Convert HEX to RGB
          </button>
          <div className="d-flex justify-content-between mb-3">
            <input
              className="form-control me-2"
              type="number"
              placeholder="R (0-255)"
              value={r}
              onChange={(e) => setR(e.target.value)}
            />
            <input
              className="form-control me-2"
              type="number"
              placeholder="G (0-255)"
              value={g}
              onChange={(e) => setG(e.target.value)}
            />
            <input
              className="form-control"
              type="number"
              placeholder="B (0-255)"
              value={b}
              onChange={(e) => setB(e.target.value)}
            />
          </div>
          <button className="btn btn-primary mb-3" onClick={handleRgbToHex}>
            Convert RGB to HEX
          </button>
          <div
            className="p-3 border text-center"
            style={{
              backgroundColor: outputRgb.startsWith("rgb") ? outputRgb : "#fff",
            }}
          >
            <p className="mb-1">
              <strong>RGB:</strong> {outputRgb}{" "}
              {outputRgb && (
                <button
                  className="btn btn-sm btn-secondary ms-2"
                  onClick={() => handleCopyToClipboard(outputRgb)}
                >
                  Copy
                </button>
              )}
            </p>
            <p>
              <strong>HEX:</strong> {outputHex}{" "}
              {outputHex && (
                <button
                  className="btn btn-sm btn-secondary ms-2"
                  onClick={() => handleCopyToClipboard(outputHex)}
                >
                  Copy
                </button>
              )}
            </p>
          </div>
          {copySuccess && <div className="text-success mt-2">{copySuccess}</div>}
          <h2>What is HEX and RGB Conversion?</h2>
          <p>
            HEX and RGB are two widely used formats for representing colors in
            digital systems. HEX uses a hexadecimal string, while RGB uses
            numeric values for red, green, and blue components. This tool allows
            you to convert between these formats effortlessly.
          </p>
          <h3>Why Use This Tool?</h3>
          <ul>
            <li>Easily convert HEX to RGB and RGB to HEX.</li>
            <li>Generate accurate color codes for web and graphic design.</li>
            <li>Visualize the resulting color instantly.</li>
          </ul>
          <h3>Applications</h3>
          <p>
            This tool is essential for web developers, graphic designers, and
            anyone working with digital colors. Use it for creating palettes,
            designing websites, or ensuring color consistency across platforms.
          </p>
        </div>
        <div className="col-lg-3">
          <div className="border bg-light p-3 text-center vertical-ads">
            {/* Placeholder for Ads */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ColorConverter;
