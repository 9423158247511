import React, { useState } from "react";
import CryptoJS from "crypto-js";

const SHA256HashGenerator = () => {
  const [input, setInput] = useState("");
  const [hash, setHash] = useState("");
  const [copySuccess, setCopySuccess] = useState(""); 
  const handleGenerateHash = () => {
    const hashResult = CryptoJS.SHA256(input).toString();
    setCopySuccess("");
    setHash(hashResult);
  };

  const handleCopyToClipboard = () => {
    if (hash) {
      navigator.clipboard.writeText(hash).then(
        () => {
          setCopySuccess("Copied to clipboard!");
        },
        (err) => {
          setCopySuccess("Failed to copy.");
        }
      );
    }

  };
  return (
    <div className="container py-5">
      <div className="row">
        <div className="col-lg-3 d-none d-lg-block">
          <div className="border bg-light p-3 text-center vertical-ads">
            {/* Placeholder for Ads */}
          </div>
        </div>
        <div className="col-lg-6">
          <h1>SHA-256 Hash Generator</h1>
          <textarea
            className="form-control mb-3"
            rows="4"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Enter text to hash"
          ></textarea>
          <button className="btn btn-primary mb-3" onClick={handleGenerateHash}>
            Generate SHA-256 Hash
          </button>
          <div className="position-relative mb-3">
            <textarea
              className="form-control"
              rows="4"
              readOnly
              value={hash}
              placeholder="Hash result will appear here"
              style={{ paddingRight: "40px" }} // Space for the icon
            ></textarea>
            {hash && (
              <button
                className="btn copy-icon"
                onClick={handleCopyToClipboard}
                aria-label="Copy to clipboard"
                style={{
                  position: "absolute",
                  top: "20%",
                  right: "-10px",
                  transform: "translateY(-50%)",
                  backgroundColor: "transparent",
                  border: "none",
                  cursor: "pointer",
                  color: "#007bff",
                  fontSize: "1.2rem",
                }}
              >📋</button>
            )}
          </div>
          {copySuccess && <span className="text-success mt-2">{copySuccess}</span>}

            <h2>What is a SHA-256 Hash Generator?</h2>
            <p>A SHA-256 (Secure Hash Algorithm 256-bit) hash generator is a cryptographic tool used to create a fixed 256-bit hash value (represented as a 64-character hexadecimal string) from input data. It is one of the most secure hashing algorithms in the SHA-2 family and is widely used for ensuring data integrity, digital signatures, and blockchain technology.</p>

            <h2>How Does SHA-256 Hashing Work?</h2>
            <p>The SHA-256 algorithm processes input data to generate a unique, fixed-length hash value. Here's a step-by-step breakdown of how it works:</p>
            <ol>
                <li><strong>Message Padding:</strong> The input data is padded to ensure its length is a multiple of 512 bits.</li>
                <li><strong>Message Segmentation:</strong> The padded message is divided into 512-bit blocks for processing.</li>
                <li><strong>Hash Initialization:</strong> Eight 32-bit initial hash values are defined by the algorithm as constants.</li>
                <li><strong>Block Processing:</strong> Each 512-bit block is processed through 64 iterations involving mathematical operations, bitwise shifts, and modular additions.</li>
                <li><strong>Final Hash Output:</strong> The result is a 256-bit hash value, represented as a 64-character hexadecimal string.</li>
            </ol>

            <h3>Example of SHA-256 Hashing</h3>
            <p>Here's an example of generating a SHA-256 hash in Python:</p>
            <div className="code-part">
            <code class="language-python">
            import hashlib<br/>

            # Input string<br/>
            input_data = "Hello, World!"<br/>
            # Generate SHA-256 hash<br/>
            sha256_hash = hashlib.sha256(input_data.encode()).hexdigest()<br/>

            print(sha256_hash)  <br/># Output: a591a6d40bf420404a011733cfb7b190d62c65bf0bcda32b53eb52feb9faba96<br/>
            </code>
           </div>

            <h2>Applications of SHA-256 Hash Generator</h2>
            <p>SHA-256 hash generators are widely used in various fields, including:</p>
            <ul>
                <li><strong>Blockchain Technology:</strong> Used to secure transactions and create unique identifiers in cryptocurrencies like Bitcoin.</li>
                <li><strong>Data Integrity:</strong> Ensures that files or data have not been altered during storage or transmission by comparing hash values.</li>
                <li><strong>Digital Signatures:</strong> Verifies the authenticity and integrity of documents or messages.</li>
                <li><strong>Password Hashing:</strong> Stores hashed passwords for authentication purposes, ensuring security against unauthorized access.</li>
            </ul>

            <h2>Advantages of SHA-256</h2>
            <p>SHA-256 offers numerous advantages, including:</p>
            <ul>
                <li><strong>High Security:</strong> Resistant to collision attacks, making it one of the most secure hashing algorithms.</li>
                <li><strong>Fixed-Length Output:</strong> Produces a consistent 256-bit hash, regardless of input size.</li>
                <li><strong>Wide Adoption:</strong> Used in modern cryptographic protocols, blockchain technologies, and secure data transmission.</li>
            </ul>

            <h2>How to Use a SHA-256 Hash Generator?</h2>
            <p>SHA-256 hash generators can be implemented in programming languages like Python, Java, or PHP. Here's an example in Python:</p>
            <div className="code-part">
            <code class="language-python">
            import hashlib<br/>

            # Input data<br/>
            data = "Generate SHA-256 Hash"<br/>
            # Create SHA-256 hash<br/>
            sha256 = hashlib.sha256(data.encode()).hexdigest()<br/>

            print(sha256)  <br/># Output: Hash value<br/>
            </code>
           </div>

            <h2>Conclusion</h2>
            <p>The SHA-256 hash generator is a powerful and secure tool for creating unique hash values. It plays a critical role in blockchain technology, data verification, and secure communication. Given its strong security features and wide adoption, SHA-256 is a preferred choice for modern cryptographic applications.</p>
            <p>Interested reading more about SHA256 Hash? Find this Wiki <a href="https://en.wikipedia.org/wiki/SHA-2">link</a>. </p>
        </div>
        <div className="col-lg-3">
          <div className="border bg-light p-3 text-center vertical-ads">
            {/* Placeholder for Ads */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SHA256HashGenerator;
