import React, { useEffect } from "react";
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import Footer from "./components/Footer";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import AboutPage from "./pages/About";
import ConactPage from "./pages/Contact";
import InOutGame from "./components/tools/InOutGame";
import SnakeGame from "./components/games/SnakeGame";


// Import tools from components/tools
import Base64Decoder from "./components/tools/Base64Decoder";
import Base64Encoder from "./components/tools/Base64Encoder";
import MD5HashGenerator from "./components/tools/MD5HashGenerator";
import SHA1HashGenerator from "./components/tools/SHA1HashGenerator";
import SHA256HashGenerator from "./components/tools/SHA256HashGenerator";
import SHA512HashGenerator from "./components/tools/SHA512HashGenerator";
import URLEncoder from "./components/tools/URLEncoder";
import URLDecoder from "./components/tools/URLDecoder"; 
import ColorMixer from "./components/tools/ColorMixer";
import ColorConverter from "./components/tools/ColorConverter";
import HtaccessGenerator from "./components/tools/HtaccessGenerator";


import TicTacToe from "./components/games/TicTacToe";
import Hangman from "./components/games/HangMan";

import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import { Helmet, HelmetProvider } from "react-helmet-async";

const initializeAnalytics = () => {
  ReactGA.initialize("G-7W1M6RCVN7"); 
};

// Component to track page views
const TrackPageView = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);

  return null;
};

const App = () => {
  useEffect(() => {
    initializeAnalytics(); // Initialize Google Analytics when the app loads
  }, []);


  const schemaDataBase64 = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "WebApplication",
        "name": "Base64 Decoder Online Tool",
        "description": "A free, fast, and reliable Base64 decoder tool to convert encoded strings into plain text. Instantly decode Base64 online.",
        "url": "https://www.helpdailyapp.com/base64-decoder",
        "applicationCategory": "Utility",
        "operatingSystem": "All",
        "softwareVersion": "1.0",
        "creator": {
          "@type": "Organization",
          "name": "Help Daily App",
          "url": "https://www.helpdailyapp.com"
        },
        "offers": {
          "@type": "Offer",
          "price": "0.00",
          "priceCurrency": "USD",
          "category": "Free"
        },
        "aggregateRating": 
        {
          "@type": "AggregateRating",
          "ratingValue": "4.8",
          "reviewCount": "125",
          "bestRating": "5",
          "worstRating": "1"
        },
        "potentialAction": {
          "@type": "UseAction",
          "target": "https://www.helpdailyapp.com/base64-decoder",
          "name": "Decode Base64 String"
        },
        "image": "https://www.helpdailyapp.com/images/help-daily-app.png",
        "keywords": "Base64 Decoder, Online Tool, Free Base64 Decode, Fast Decoder"
      },
      {
        "@type": "FAQPage",
        "mainEntity": [
          {
            "@type": "Question",
            "name": "What is a Base64 Decoder?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "A Base64 Decoder is a tool that decodes encoded Base64 strings into plain text or binary data."
            }
          },
          {
            "@type": "Question",
            "name": "How does the Base64 Decoder work?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "The Base64 Decoder converts Base64 encoded data into human-readable text by reversing the Base64 encoding algorithm."
            }
          },
          {
            "@type": "Question",
            "name": "Is the Base64 Decoder free?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Yes, this Base64 Decoder is completely free to use."
            }
          }
        ]
      }
    ]
  };

  const schemaDataBase64Encoder = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "WebApplication",
        "name": "Base64 Encoder Online Tool",
        "description": "A free, fast, and reliable Base64 encoder tool to convert plain text into encoded Base64 strings. Instantly encode Base64 online.",
        "url": "https://www.helpdailyapp.com/base64-encoder",
        "applicationCategory": "Utility",
        "operatingSystem": "All",
        "softwareVersion": "1.0",
        "creator": {
          "@type": "Organization",
          "name": "Help Daily App",
          "url": "https://www.helpdailyapp.com"
        },
        "offers": {
          "@type": "Offer",
          "price": "0.00",
          "priceCurrency": "USD",
          "category": "Free"
        }, 
        "aggregateRating": 
        {
        "@type": "AggregateRating",
        "ratingValue": "4.8",
        "reviewCount": "125",
        "bestRating": "5",
        "worstRating": "1"
      },
        "potentialAction": {
          "@type": "UseAction",
          "target": "https://www.helpdailyapp.com/base64-encoder",
          "name": "Encode Base64 String"
        },
        "image": "https://www.helpdailyapp.com/images/help-daily-app.png",
        "keywords": "Base64 Encoder, Online Tool, Free Base64 Encode, Fast Encoder"
      },
      {
        "@type": "FAQPage",
        "mainEntity": [
          {
            "@type": "Question",
            "name": "What is a Base64 Encoder?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "A Base64 Encoder is a tool that converts plain text or binary data into encoded Base64 strings."
            }
          },
          {
            "@type": "Question",
            "name": "How does the Base64 Encoder work?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "The Base64 Encoder converts text into Base64 encoded data using the Base64 encoding algorithm, which represents binary data in an ASCII string format."
            }
          },
          {
            "@type": "Question",
            "name": "Is the Base64 Encoder free?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Yes, this Base64 Encoder is completely free to use."
            }
          }
        ]
      }
    ]
  };
  
  const schemaDataMD5Hash = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "WebApplication",
        "name": "MD5 Hash Generator Online Tool",
        "description": "A free, fast, and reliable MD5 hash generator to convert any string into a unique 128-bit hash value. Instantly generate MD5 hashes online.",
        "url": "https://www.helpdailyapp.com/md5-hash-generator",
        "applicationCategory": "Utility",
        "operatingSystem": "All",
        "softwareVersion": "1.0",
        "creator": {
          "@type": "Organization",
          "name": "Help Daily App",
          "url": "https://www.helpdailyapp.com"
        },
        "offers": {
          "@type": "Offer",
          "price": "0.00",
          "priceCurrency": "USD",
          "category": "Free"
        }, "aggregateRating": {
    "@type": "AggregateRating",
    "ratingValue": "4.8",
    "reviewCount": "125",
    "bestRating": "5",
    "worstRating": "1"
  },
        "potentialAction": {
          "@type": "UseAction",
          "target": "https://www.helpdailyapp.com/md5-hash-generator",
          "name": "Generate MD5 Hash"
        },
        "image": "https://www.helpdailyapp.com/images/help-daily-app.png",
        "keywords": "MD5 Hash Generator, Online Tool, Free MD5 Generator, Fast Hash Generator"
      },
      {
        "@type": "FAQPage",
        "mainEntity": [
          {
            "@type": "Question",
            "name": "What is an MD5 Hash Generator?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "An MD5 Hash Generator is a tool that converts strings into a fixed-length 128-bit hash value using the MD5 hashing algorithm."
            }
          },
          {
            "@type": "Question",
            "name": "How does the MD5 Hash Generator work?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "The MD5 Hash Generator applies the MD5 algorithm to any input string to produce a unique hash value. This hash is commonly used for data integrity and security purposes."
            }
          },
          {
            "@type": "Question",
            "name": "Is the MD5 Hash Generator free?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Yes, this MD5 Hash Generator is completely free to use."
            }
          },
          {
            "@type": "Question",
            "name": "Can MD5 hashes be reversed?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "No, MD5 hashes are designed to be irreversible. However, some hashes can be cracked using lookup tables or brute-force attacks."
            }
          }
        ]
      }
    ]
  };
  
  const schemaDataSHA1Hash = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "WebApplication",
        "name": "SHA-1 Hash Generator Online Tool",
        "description": "A free, fast, and reliable SHA-1 hash generator to convert any string into a secure 160-bit hash value. Instantly generate SHA-1 hashes online.",
        "url": "https://www.helpdailyapp.com/sha1-hash-generator",
        "applicationCategory": "Utility",
        "operatingSystem": "All",
        "softwareVersion": "1.0",
        "creator": {
          "@type": "Organization",
          "name": "Help Daily App",
          "url": "https://www.helpdailyapp.com"
        },
        "offers": {
          "@type": "Offer",
          "price": "0.00",
          "priceCurrency": "USD",
          "category": "Free"
        }, "aggregateRating": {
    "@type": "AggregateRating",
    "ratingValue": "4.8",
    "reviewCount": "125",
    "bestRating": "5",
    "worstRating": "1"
  },
        "potentialAction": {
          "@type": "UseAction",
          "target": "https://www.helpdailyapp.com/sha1-hash-generator",
          "name": "Generate SHA-1 Hash"
        },
        "image": "https://www.helpdailyapp.com/images/help-daily-app.png",
        "keywords": "SHA-1 Hash Generator, Online Tool, Free SHA-1 Generator, Fast Hash Generator"
      },
      {
        "@type": "FAQPage",
        "mainEntity": [
          {
            "@type": "Question",
            "name": "What is a SHA-1 Hash Generator?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "A SHA-1 Hash Generator is a tool that converts strings into a secure 160-bit hash value using the SHA-1 hashing algorithm."
            }
          },
          {
            "@type": "Question",
            "name": "How does the SHA-1 Hash Generator work?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "The SHA-1 Hash Generator applies the SHA-1 algorithm to any input string to produce a unique, secure hash value, widely used for data verification."
            }
          },
          {
            "@type": "Question",
            "name": "Is the SHA-1 Hash Generator free?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Yes, this SHA-1 Hash Generator is completely free to use."
            }
          },
          {
            "@type": "Question",
            "name": "Is SHA-1 secure?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "SHA-1 is considered outdated for cryptographic security due to vulnerabilities to collision attacks. It is still used for some legacy systems but is not recommended for secure applications."
            }
          }
        ]
      }
    ]
  };
  
  const schemaDataSHA256Hash = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "WebApplication",
        "name": "SHA-256 Hash Generator Online Tool",
        "description": "A free, fast, and reliable SHA-256 hash generator to convert any string into a secure 256-bit hash value. Instantly generate SHA-256 hashes online.",
        "url": "https://www.helpdailyapp.com/sha256-hash-generator",
        "applicationCategory": "Utility",
        "operatingSystem": "All",
        "softwareVersion": "1.0",
        "creator": {
          "@type": "Organization",
          "name": "Help Daily App",
          "url": "https://www.helpdailyapp.com"
        },
        "offers": {
          "@type": "Offer",
          "price": "0.00",
          "priceCurrency": "USD",
          "category": "Free"
        }, "aggregateRating": {
    "@type": "AggregateRating",
    "ratingValue": "4.8",
    "reviewCount": "125",
    "bestRating": "5",
    "worstRating": "1"
  },
        "potentialAction": {
          "@type": "UseAction",
          "target": "https://www.helpdailyapp.com/sha256-hash-generator",
          "name": "Generate SHA-256 Hash"
        },
        "image": "https://www.helpdailyapp.com/images/help-daily-app.png",
        "keywords": "SHA-256 Hash Generator, Online Tool, Free SHA-256 Generator, Secure Hash Generator"
      },
      {
        "@type": "FAQPage",
        "mainEntity": [
          {
            "@type": "Question",
            "name": "What is a SHA-256 Hash Generator?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "A SHA-256 Hash Generator is a tool that converts strings into a secure 256-bit hash value using the SHA-256 hashing algorithm."
            }
          },
          {
            "@type": "Question",
            "name": "How does the SHA-256 Hash Generator work?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "The SHA-256 Hash Generator applies the SHA-256 algorithm to any input string to produce a unique, secure hash value commonly used for data security and cryptography."
            }
          },
          {
            "@type": "Question",
            "name": "Is the SHA-256 Hash Generator free?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Yes, this SHA-256 Hash Generator is completely free to use."
            }
          },
          {
            "@type": "Question",
            "name": "Is SHA-256 secure?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Yes, SHA-256 is currently considered one of the most secure hashing algorithms and is widely used in cryptographic applications and blockchain technology."
            }
          }
        ]
      }
    ]
  };
  const schemaDataSHA512Hash = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "WebApplication",
        "name": "SHA-512 Hash Generator Online Tool",
        "description": "A free, fast, and reliable SHA-512 hash generator to convert any string into a secure 512-bit hash value. Instantly generate SHA-512 hashes online.",
        "url": "https://www.helpdailyapp.com/sha512-hash-generator",
        "applicationCategory": "Utility",
        "operatingSystem": "All",
        "softwareVersion": "1.0",
        "creator": {
          "@type": "Organization",
          "name": "Help Daily App",
          "url": "https://www.helpdailyapp.com"
        },
        "offers": {
          "@type": "Offer",
          "price": "0.00",
          "priceCurrency": "USD",
          "category": "Free"
        }, "aggregateRating": {
    "@type": "AggregateRating",
    "ratingValue": "4.8",
    "reviewCount": "125",
    "bestRating": "5",
    "worstRating": "1"
  },
        "potentialAction": {
          "@type": "UseAction",
          "target": "https://www.helpdailyapp.com/sha512-hash-generator",
          "name": "Generate SHA-512 Hash"
        },
        "image": "https://www.helpdailyapp.com/images/help-daily-app.png",
        "keywords": "SHA-512 Hash Generator, Online Tool, Free SHA-512 Generator, Secure Hash Generator"
      },
      {
        "@type": "FAQPage",
        "mainEntity": [
          {
            "@type": "Question",
            "name": "What is a SHA-512 Hash Generator?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "A SHA-512 Hash Generator is a tool that converts strings into a secure 512-bit hash value using the SHA-512 hashing algorithm."
            }
          },
          {
            "@type": "Question",
            "name": "How does the SHA-512 Hash Generator work?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "The SHA-512 Hash Generator applies the SHA-512 algorithm to any input string to produce a unique, secure hash value, commonly used in cryptography for data integrity and security."
            }
          },
          {
            "@type": "Question",
            "name": "Is the SHA-512 Hash Generator free?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Yes, this SHA-512 Hash Generator is completely free to use."
            }
          },
          {
            "@type": "Question",
            "name": "Is SHA-512 secure?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Yes, SHA-512 is considered highly secure and is widely used in cryptographic applications that require high levels of security."
            }
          }
        ]
      }
    ]
  };
  
  const schemaDataURLDecoder = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "WebApplication",
        "name": "URL Decoder Online Tool",
        "description": "A free, fast, and reliable URL decoder to decode encoded URLs into a human-readable format. Instantly decode URLs online.",
        "url": "https://www.helpdailyapp.com/url-decoder",
        "applicationCategory": "Utility",
        "operatingSystem": "All",
        "softwareVersion": "1.0",
        "creator": {
          "@type": "Organization",
          "name": "Help Daily App",
          "url": "https://www.helpdailyapp.com"
        },
        "offers": {
          "@type": "Offer",
          "price": "0.00",
          "priceCurrency": "USD",
          "category": "Free"
        }, "aggregateRating": {
    "@type": "AggregateRating",
    "ratingValue": "4.8",
    "reviewCount": "125",
    "bestRating": "5",
    "worstRating": "1"
  },
        "potentialAction": {
          "@type": "UseAction",
          "target": "https://www.helpdailyapp.com/url-decoder",
          "name": "Decode URL"
        },
        "image": "https://www.helpdailyapp.com/images/help-daily-app.png",
        "keywords": "URL Decoder, Online Tool, Free URL Decode, Fast URL Decoder"
      },
      {
        "@type": "FAQPage",
        "mainEntity": [
          {
            "@type": "Question",
            "name": "What is a URL Decoder?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "A URL Decoder is a tool that converts percent-encoded URLs into their original, human-readable format."
            }
          },
          {
            "@type": "Question",
            "name": "How does the URL Decoder work?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "The URL Decoder processes encoded URLs by replacing percent-encoded characters (e.g., %20) with their original characters, making the URL readable."
            }
          },
          {
            "@type": "Question",
            "name": "Is the URL Decoder free?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Yes, this URL Decoder is completely free to use."
            }
          },
          {
            "@type": "Question",
            "name": "Why do URLs need decoding?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "URLs may be encoded to safely transmit special characters over the internet. Decoding is necessary to convert them back to their original format for readability or further processing."
            }
          }
        ]
      }
    ]
  };
  
  const schemaColorMixer = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "WebApplication",
        "name": "Online Color Mixer Tool",
        "description": "An intuitive and free online color mixer tool for creating unique color blends. Adjust mix ratios, select custom colors, and generate hex codes for your projects.",
        "url": "https://www.helpdailyapp.com/color-mixer",
        "applicationCategory": "Utility",
        "operatingSystem": "All",
        "softwareVersion": "1.0",
        "creator": {
          "@type": "Organization",
          "name": "Help Daily App",
          "url": "https://www.helpdailyapp.com"
        },
        "offers": {
          "@type": "Offer",
          "price": "0.00",
          "priceCurrency": "USD",
          "category": "Free"
        },
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": "4.9",
          "reviewCount": "150",
          "bestRating": "5",
          "worstRating": "1"
        },
        "potentialAction": {
          "@type": "UseAction",
          "target": "https://www.helpdailyapp.com/color-mixer",
          "name": "Mix Colors Online"
        },
        "image": "https://www.helpdailyapp.com/images/help-daily-app.png",
        "keywords": "Color Mixer, Online Tool, Free Color Mixing, Hex Code Generator, Color Blending"
      },
      {
        "@type": "FAQPage",
        "mainEntity": [
          {
            "@type": "Question",
            "name": "What is the Online Color Mixer Tool?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "The Online Color Mixer Tool allows you to blend two colors, adjust the mix ratio, and generate unique color combinations along with their hex codes."
            }
          },
          {
            "@type": "Question",
            "name": "How does the Color Mixer Tool work?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Simply select two colors using the color pickers, adjust the mix ratio slider, and the tool will display the resulting color with its hexadecimal value in real time."
            }
          },
          {
            "@type": "Question",
            "name": "Is the Color Mixer Tool free?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Yes, the Online Color Mixer Tool is completely free to use for everyone."
            }
          },
          {
            "@type": "Question",
            "name": "What are the applications of the Color Mixer Tool?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "The tool is perfect for web and graphic design, creating color palettes, blending colors for art projects, and generating hex codes for digital use."
            }
          }
        ]
      }
    ]
  };
   
  const schemaColorConverter = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "WebApplication",
        "name": "Online Color Converter Tool",
        "description": "A powerful and free online tool for converting colors between HEX and RGB formats. Includes a color mixer for creating unique blends, generating hex codes, and adjusting color ratios.",
        "url": "https://www.helpdailyapp.com/color-converter",
        "applicationCategory": "Utility",
        "operatingSystem": "All",
        "softwareVersion": "1.1",
        "creator": {
          "@type": "Organization",
          "name": "Help Daily App",
          "url": "https://www.helpdailyapp.com"
        },
        "offers": {
          "@type": "Offer",
          "price": "0.00",
          "priceCurrency": "USD",
          "category": "Free"
        },
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": "4.8",
          "reviewCount": "200",
          "bestRating": "5",
          "worstRating": "1"
        },
        "potentialAction": {
          "@type": "UseAction",
          "target": "https://www.helpdailyapp.com/color-converter",
          "name": "Convert Colors Online"
        },
        "image": "https://www.helpdailyapp.com/images/help-daily-app.png",
        "keywords": "Color Converter, HEX to RGB, RGB to HEX, Online Tool, Free Color Mixing, Color Code Generator, HEX and RGB"
      },
      {
        "@type": "FAQPage",
        "mainEntity": [
          {
            "@type": "Question",
            "name": "What is the Online Color Converter Tool?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "The Online Color Converter Tool helps you convert colors between HEX and RGB formats effortlessly. It also includes a color mixer to blend colors and generate unique combinations."
            }
          },
          {
            "@type": "Question",
            "name": "How do I use the Color Converter Tool?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Enter a HEX code or RGB value in the input fields, and the tool will instantly convert it to the corresponding format. You can also use the color mixer for custom color blending."
            }
          },
          {
            "@type": "Question",
            "name": "Is the Color Converter Tool free?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Yes, the Online Color Converter Tool is completely free for all users."
            }
          },
          {
            "@type": "Question",
            "name": "What are the benefits of using the Color Converter Tool?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "The tool simplifies the process of converting between HEX and RGB formats, creating custom color palettes, and generating hex codes for digital and design projects."
            }
          },
          {
            "@type": "Question",
            "name": "What is the difference between HEX and RGB color formats?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "HEX is a hexadecimal representation of colors, primarily used in web development, while RGB is a color model based on red, green, and blue values, commonly used in digital displays and design."
            }
          }
        ]
      }
    ]
  };
  
  const schemaHtaccessGenerator = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "WebApplication",
        "name": "Online .htaccess Generator Tool",
        "description": "Generate custom .htaccess files easily with our free Online .htaccess Generator Tool. Configure SEO-friendly redirects, access control, caching, error pages, and more to optimize your website.",
        "url": "https://www.helpdailyapp.com/htaccess-generator",
        "applicationCategory": "Utility",
        "operatingSystem": "All",
        "softwareVersion": "1.0",
        "creator": {
          "@type": "Organization",
          "name": "Help Daily App",
          "url": "https://www.helpdailyapp.com"
        },
        "offers": {
          "@type": "Offer",
          "price": "0.00",
          "priceCurrency": "USD",
          "category": "Free"
        },
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": "4.9",
          "reviewCount": "150",
          "bestRating": "5",
          "worstRating": "1"
        },
        "potentialAction": {
          "@type": "UseAction",
          "target": "https://www.helpdailyapp.com/htaccess-generator",
          "name": "Generate .htaccess Files Online"
        },
        "image": "https://www.helpdailyapp.com/images/help-daily-app.png",
        "keywords": ".htaccess generator, SEO redirects, access control, caching rules, custom error pages, website security, free .htaccess tool, optimize website, generate htaccess"
      },
      {
        "@type": "FAQPage",
        "mainEntity": [
          {
            "@type": "Question",
            "name": "What is the Online .htaccess Generator Tool?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "The Online .htaccess Generator Tool allows you to create custom .htaccess files for configuring redirects, access control, caching, and error pages easily."
            }
          },
          {
            "@type": "Question",
            "name": "How do I use the .htaccess Generator Tool?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Input your desired directives (e.g., redirects, caching rules) into the provided fields, and the tool will generate a ready-to-use .htaccess file for you."
            }
          },
          {
            "@type": "Question",
            "name": "Is the .htaccess Generator Tool free?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Yes, the Online .htaccess Generator Tool is completely free to use."
            }
          },
          {
            "@type": "Question",
            "name": "What are the benefits of using the .htaccess Generator Tool?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "The tool simplifies the creation of .htaccess files, enabling you to implement SEO-friendly redirects, optimize performance with caching rules, and enhance website security effortlessly."
            }
          },
          {
            "@type": "Question",
            "name": "What is an .htaccess file?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": ".htaccess is a configuration file for Apache servers that allows you to manage server settings at the directory level, including redirects, caching, access control, and error pages."
            }
          }
        ]
      }
    ]
  };
  
  const schemaTikTakToe = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "WebApplication",
        "name": "Tic Tac Toe Online Game",
        "description": "Play the classic Tic Tac Toe game online for free. Challenge the computer, improve your strategy, and enjoy endless fun!",
        "url": "https://www.helpdailyapp.com/tic-tac-toe",
        "applicationCategory": "Game",
        "operatingSystem": "All",
        "softwareVersion": "1.0",
        "creator": {
          "@type": "Organization",
          "name": "Help Daily App",
          "url": "https://www.helpdailyapp.com"
        },
        "offers": {
          "@type": "Offer",
          "price": "0.00",
          "priceCurrency": "USD",
          "category": "Free"
        },
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": "4.8",
          "reviewCount": "200",
          "bestRating": "5",
          "worstRating": "1"
        },
        "potentialAction": {
          "@type": "PlayAction",
          "target": "https://www.helpdailyapp.com/tic-tac-toe",
          "name": "Play Tic Tac Toe Online"
        },
        "image": "https://www.helpdailyapp.com/images/tic-tac-toe-tool.png",
        "keywords": "Tic Tac Toe, Online Game, Free Tic Tac Toe, Play Tic Tac Toe Online, Strategy Game, Fun Games"
      },
      {
        "@type": "FAQPage",
        "mainEntity": [
          {
            "@type": "Question",
            "name": "What is the Tic Tac Toe Online Game?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "The Tic Tac Toe Online Game is a digital version of the classic game where you play against a computer. Try to get three X's in a row before the computer places three O's."
            }
          },
          {
            "@type": "Question",
            "name": "How do I play Tic Tac Toe online?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Click on an empty square to place your X. The computer will respond with an O. The goal is to align three of your marks in a row, column, or diagonal to win."
            }
          },
          {
            "@type": "Question",
            "name": "Is the Tic Tac Toe Online Game free?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Yes, the Tic Tac Toe Online Game is completely free to play."
            }
          },
          {
            "@type": "Question",
            "name": "Can I play Tic Tac Toe on mobile devices?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Yes, the game is fully responsive and works seamlessly on desktops, tablets, and mobile devices."
            }
          }
        ]
      }
    ]
  };
  
  const schemaSnakeGame = { 
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "WebApplication",
        "name": "Online Snake Game",
        "description": "Play the classic Snake Game online! Maneuver your snake, eat the food, and grow longer while avoiding collisions. Enjoy a nostalgic arcade adventure that's perfect for all ages.",
        "url": "https://www.helpdailyapp.com/snake-game",
        "applicationCategory": "Game",
        "operatingSystem": "All",
        "softwareVersion": "1.0",
        "creator": {
          "@type": "Organization",
          "name": "Help Daily App",
          "url": "https://www.helpdailyapp.com"
        },
        "offers": {
          "@type": "Offer",
          "price": "0.00",
          "priceCurrency": "USD",
          "category": "Free"
        },
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": "4.8",
          "reviewCount": "200",
          "bestRating": "5",
          "worstRating": "1"
        },
        "potentialAction": {
          "@type": "PlayAction",
          "target": "https://www.helpdailyapp.com/snake-game",
          "name": "Play Snake Game Online"
        },
        "image": "https://www.helpdailyapp.com/images/help-daily-app.png",
        "keywords": "Snake Game, Play Snake Online, Classic Arcade Game, Fun Games, Retro Gaming, Online Snake Game"
      },
      {
        "@type": "FAQPage",
        "mainEntity": [
          {
            "@type": "Question",
            "name": "What is the Snake Game?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "The Snake Game is a classic arcade game where players guide a snake to eat food and grow longer while avoiding collisions with walls or its own tail."
            }
          },
          {
            "@type": "Question",
            "name": "How do I play the Snake Game?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Use the arrow keys to guide the snake. Eat the food to grow longer, and avoid crashing into walls or your tail to keep playing."
            }
          },
          {
            "@type": "Question",
            "name": "Is the Snake Game free to play?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Yes, the Snake Game is completely free to play online."
            }
          },
          {
            "@type": "Question",
            "name": "What are the benefits of playing the Snake Game?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "The Snake Game improves reflexes, strategic thinking, and hand-eye coordination while offering a fun and nostalgic gaming experience."
            }
          },
          {
            "@type": "Question",
            "name": "Can I play the Snake Game on any device?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Yes, the Snake Game can be played on any device with a web browser, including desktop, mobile, and tablet."
            }
          }
        ]
      }
    ]
  };

  const schemaDataInOutGame = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "WebApplication",
        "name": "In or Out Game - Reflex Challenge",
        "description": "A fun and interactive reflex-based game where players follow commands to step 'In' or 'Out' of the circle. Perfect for all ages, with engaging visuals and sound effects.",
        "url": "https://www.helpdailyapp.com/in-out-game",
        "applicationCategory": "Game",
        "operatingSystem": "All",
        "softwareVersion": "1.0",
        "creator": {
          "@type": "Organization",
          "name": "Help Daily App",
          "url": "https://www.helpdailyapp.com"
        },
        "offers": {
          "@type": "Offer",
          "price": "0.00",
          "priceCurrency": "USD",
          "category": "Free"
        },
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": "4.9",
          "reviewCount": "85",
          "bestRating": "5",
          "worstRating": "1"
        },
        "potentialAction": {
          "@type": "PlayAction",
          "target": "https://www.helpdailyapp.com/in-out-game",
          "name": "Play In or Out Game"
        },
        "image": "https://www.helpdailyapp.com/images/in-or-out-game.png",
        "keywords": "In or Out Game, Reflex Game, Online Game, Fun Challenge, Interactive Game"
      },
      {
        "@type": "FAQPage",
        "mainEntity": [
          {
            "@type": "Question",
            "name": "What is the In or Out Game?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "The In or Out Game is a reflex-based game where players respond to commands by stepping 'In' or 'Out' of a circle. It’s engaging and fun for all ages."
            }
          },
          {
            "@type": "Question",
            "name": "How do you play the In or Out Game?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "To play, simply follow the commands displayed on the screen: step 'In' when prompted, or step 'Out' when required. The circle and rectangle highlight dynamically guide your actions."
            }
          },
          {
            "@type": "Question",
            "name": "Is the In or Out Game free to play?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Yes, the In or Out Game is completely free to play online."
            }
          },
          {
            "@type": "Question",
            "name": "Who can play the In or Out Game?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "This game is suitable for all ages, making it perfect for kids, teens, and adults. It’s a great choice for office breaks or family fun."
            }
          }
        ]
      }
    ]
  };

  const schemaDataURLEncoder = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "WebApplication",
        "name": "URL Encoder Online Tool",
        "description": "A free, fast, and reliable URL encoder to convert URLs into a safe format for transmission over the internet. Instantly encode URLs online.",
        "url": "https://www.helpdailyapp.com/url-encoder",
        "applicationCategory": "Utility",
        "operatingSystem": "All",
        "softwareVersion": "1.0",
        "creator": {
          "@type": "Organization",
          "name": "Help Daily App",
          "url": "https://www.helpdailyapp.com"
        },
        "offers": {
          "@type": "Offer",
          "price": "0.00",
          "priceCurrency": "USD",
          "category": "Free"
        }, "aggregateRating": {
    "@type": "AggregateRating",
    "ratingValue": "4.8",
    "reviewCount": "125",
    "bestRating": "5",
    "worstRating": "1"
  },
        "potentialAction": {
          "@type": "UseAction",
          "target": "https://www.helpdailyapp.com/url-encoder",
          "name": "Encode URL"
        },
        "image": "https://www.helpdailyapp.com/images/help-daily-app.png",
        "keywords": "URL Encoder, Online Tool, Free URL Encode, Fast URL Encoder"
      },
      {
        "@type": "FAQPage",
        "mainEntity": [
          {
            "@type": "Question",
            "name": "What is a URL Encoder?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "A URL Encoder is a tool that converts characters in URLs into a safe format for transmission over the internet by replacing special characters with percent-encoded values."
            }
          },
          {
            "@type": "Question",
            "name": "How does the URL Encoder work?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "The URL Encoder processes input text by replacing unsafe characters with their percent-encoded equivalents, ensuring compatibility with web protocols."
            }
          },
          {
            "@type": "Question",
            "name": "Is the URL Encoder free?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Yes, this URL Encoder is completely free to use."
            }
          },
          {
            "@type": "Question",
            "name": "Why do URLs need encoding?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "URLs need encoding to ensure that special characters are properly transmitted over the internet without causing errors or misinterpretation."
            }
          }
        ]
      }
    ]
  };

  const schemaDataOverallApp = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "WebSite",
        "name": "Help Daily App",
        "url": "https://www.helpdailyapp.com",
        "description": "Help Daily App provides a suite of online tools, including Base64 Encoder/Decoder, MD5, SHA-1, SHA-256, URL Encoder/Decoder, and more, for free and efficient use.",
        "potentialAction": {
          "@type": "SearchAction",
          "target": "https://www.helpdailyapp.com/search?q={search_term_string}",
          "query-input": "required name=search_term_string"
        }
      },
      {
        "@type": "WebApplication",
        "name": "Help Daily App",
        "description": "A collection of free, fast, and reliable online tools like Base64 Encoder/Decoder, MD5, SHA-1, SHA-256, and URL Encoder/Decoder for developers and everyday users.",
        "url": "https://www.helpdailyapp.com",
        "applicationCategory": "Utility",
        "operatingSystem": "All",
        "softwareVersion": "1.0",
        "creator": {
          "@type": "Organization",
          "name": "Help Daily App",
          "url": "https://www.helpdailyapp.com"
        },
        "offers": {
          "@type": "Offer",
          "price": "0.00",
          "priceCurrency": "USD",
          "category": "Free"
        }, 
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": "4.8",
          "reviewCount": "125",
          "bestRating": "5",
          "worstRating": "1"
        },
        "image": "https://www.helpdailyapp.com/images/help-daily-app.png",
        "keywords": "Online Tools, Base64 Encoder, Base64 Decoder, MD5, SHA-1, SHA-256, URL Encoder, URL Decoder, Free Online Tools"
      }
    ]
  };
  const schemaHangmanGame = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "WebApplication",
        "name": "Hangman Game - General Knowledge Edition",
        "description":
          "A fun and educational online Hangman game that combines word guessing with trivia questions. Test your knowledge and vocabulary with this engaging tool.",
        "url": "https://www.helpdailyapp.com/hangman",
        "applicationCategory": "Game",
        "operatingSystem": "All",
        "softwareVersion": "1.0",
        "creator": {
          "@type": "Organization",
          "name": "Help Daily App",
          "url": "https://www.helpdailyapp.com",
        },
        "offers": {
          "@type": "Offer",
          "price": "0.00",
          "priceCurrency": "USD",
          "category": "Free",
        },
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": "4.9",
          "reviewCount": "200",
          "bestRating": "5",
          "worstRating": "1",
        },
        "potentialAction": {
          "@type": "PlayAction",
          "target": "https://www.helpdailyapp.com/hangman",
          "name": "Play Hangman Game",
        },
        "image": "https://www.helpdailyapp.com/images/help-daily-app.png",
        "keywords":
          "Hangman Game, Trivia Quiz, General Knowledge Hangman, Word Guessing Game, Educational Games Online",
      },
      {
        "@type": "FAQPage",
        "mainEntity": [
          {
            "@type": "Question",
            "name": "What is the Hangman Game?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "The Hangman Game is a classic word guessing game where players try to uncover a hidden word by guessing letters, with a fun twist of general knowledge questions.",
            },
          },
          {
            "@type": "Question",
            "name": "How do I play the Hangman Game?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "To play the Hangman Game, read the trivia question, guess letters for the hidden answer, and avoid exceeding the maximum number of incorrect guesses.",
            },
          },
          {
            "@type": "Question",
            "name": "Is the Hangman Game free?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Yes, the Hangman Game is completely free to play online.",
            },
          },
          {
            "@type": "Question",
            "name": "What are the benefits of playing the Hangman Game?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Playing the Hangman Game improves vocabulary, enhances problem-solving skills, and is a fun way to test your general knowledge.",
            },
          },
        ],
      },
    ],
  };
  
  return (
    <HelmetProvider>
      <Router>
        <TrackPageView /> {/* Tracks page views */}
        <Navbar />
        <Routes>
          {/* Main Pages */}
          <Route
            path="/"
            element={
              <>
                <Helmet>
                  <title>Home - Help Daily App</title>
                  <meta name="description" content="Welcome to Help Daily App. Access various tools like encoders, hash generators, and more." />
                  <meta name="keywords" content="tools, base64 encoder, hash generator, URL encoder, free tools" />
                  <script type="application/ld+json">
                    {JSON.stringify(schemaDataOverallApp)}
                  </script>
                </Helmet>
                <Home />
              </>
            }
          />
          <Route
            path="/tools"
            element={
              <>
                <Helmet>
                  <title>Tools - Help Daily App</title>
                  <meta name="description" content="Explore all the tools we offer, including encoders, decoders, and hash generators." />
                  <meta name="keywords" content="tools, online tools, free tools, hash generators" />
                </Helmet>
                <div>Tools Page</div>
              </>
            }
          />
          <Route
            path="/about"
            element={
              <>
                <Helmet>
                  <title>About Us - Help Daily App</title>
                  <meta name="description" content="Learn more about Help Daily App and how it can help you with various tasks." />
                  <meta name="keywords" content="about us, tools app, free tools online" />
                </Helmet>
                <AboutPage />
              </>
            }
          />
          <Route
            path="/contact"
            element={
              <>
                <Helmet>
                  <title>Contact Us - Help Daily App</title>
                  <meta name="description" content="Get in touch with us for feedback, support, or any inquiries." />
                  <meta name="keywords" content="contact, support, tools app" />
                </Helmet>
                <ConactPage />
              </>
            }
          />
          <Route
            path="/privacy-policy"
            element={
              <>
                <Helmet>
                  <title>Privacy Policy - Help Daily App</title>
                  <meta name="description" content="Read our privacy policy to understand how we handle your data." />
                  <meta name="keywords" content="privacy policy, tools app, data handling" />
                </Helmet>
                <PrivacyPolicy />
              </>
            }
          />

          {/* Tool Pages */}
          <Route
            path="/base64-decoder"
            element={
              <>
                <Helmet>
                  <title>Base64 Decoder - Help Daily App</title>
                  <meta name="description" content="Decode Base64 strings to readable text using our free tool." />
                  <meta name="keywords" content="base64 decoder, decode base64, free tools" />
                  <script type="application/ld+json">
                    {JSON.stringify(schemaDataBase64)}
                  </script>
                </Helmet>
                <Base64Decoder />
              </>
            }
          />
          <Route
            path="/base64-encoder"
            element={
              <>
                <Helmet>
                  <title>Base64 Encoder - Help Daily App</title>
                  <meta name="description" content="Encode text into Base64 format quickly and easily." />
                  <meta name="keywords" content="base64 encoder, encode base64, free tools" />
                  <script type="application/ld+json">
                    {JSON.stringify(schemaDataBase64Encoder)}
                  </script>
                </Helmet>
                <Base64Encoder />
              </>
            }
          />
          <Route
            path="/md5-hash-generator"
            element={
              <>
                <Helmet>
                  <title>MD5 Hash Generator - Help Daily App</title>
                  <meta name="description" content="Generate MD5 hashes for your strings using our secure tool." />
                  <meta name="keywords" content="md5 hash, hash generator, online tools" />
                  <script type="application/ld+json">
                    {JSON.stringify(schemaDataMD5Hash)} 
                  </script>
                </Helmet>
                <MD5HashGenerator />
              </>
            }
          />
          <Route
            path="/sha1-hash-generator"
            element={
              <>
                <Helmet>
                  <title>SHA-1 Hash Generator - Help Daily App</title>
                  <meta name="description" content="Create secure SHA-1 hashes for any string or data." />
                  <meta name="keywords" content="sha1 hash, hash generator, secure tools" />
                  <script type="application/ld+json">
                    {JSON.stringify(schemaDataSHA1Hash)} 
                  </script>
                </Helmet>
                <SHA1HashGenerator />
              </>
            }
          />
          <Route
            path="/sha256-hash-generator"
            element={
              <>
                <Helmet>
                  <title>SHA-256 Hash Generator - Help Daily App</title>
                  <meta name="description" content="Generate SHA-256 hashes with our reliable tool." />
                  <meta name="keywords" content="sha256 hash, hash generator, secure tools" />
                  <script type="application/ld+json">
                    {JSON.stringify(schemaDataSHA256Hash)} 
                  </script>
                </Helmet>
                <SHA256HashGenerator />
              </>
            }
          />
          <Route
            path="/sha512-hash-generator"
            element={
              <>
                <Helmet>
                  <title>SHA-512 Hash Generator - Help Daily App</title>
                  <meta name="description" content="Create SHA-512 hashes easily with our online generator." />
                  <meta name="keywords" content="sha512 hash, hash generator, secure tools" />
                  <script type="application/ld+json">
                    {JSON.stringify(schemaDataSHA512Hash)} 
                  </script>
                </Helmet>
                <SHA512HashGenerator />
              </>
            }
          />
          <Route
            path="/url-encoder"
            element={
              <>
                <Helmet>
                  <title>URL Encoder - Help Daily App</title>
                  <meta name="description" content="Encode URLs into safe formats for use in web applications." />
                  <meta name="keywords" content="url encoder, encode url, online tools" />
                  <script type="application/ld+json">
                    {JSON.stringify(schemaDataURLEncoder)} 
                  </script>
                </Helmet>
                <URLEncoder />
              </>
            }
          />
          <Route
            path="/url-decoder"
            element={
              <>
                <Helmet>
                  <title>URL Decoder - Help Daily App</title>
                  <meta name="description" content="Decode encoded URLs back into their readable form." />
                  <meta name="keywords" content="url decoder, decode url, online tools" />
                  <script type="application/ld+json">
                    {JSON.stringify(schemaDataURLDecoder)} 
                  </script>
                </Helmet>
                <URLDecoder />
              </>
            }
          />
         <Route
            path="/color-mixer"
            element={
              <>
                <Helmet>
                  <title>Color mixer tool</title>
                  <meta name="description" content="Blend colors effortlessly with the Online Color Mixer Tool. Select colors, adjust mix ratios, and generate unique hex codes for web and graphic design." />
                  <meta name="keywords" content="color mixer, online color mixing, blend colors, hex code generator, color blending tool, free color mixer" />

                  <script type="application/ld+json">
                    {JSON.stringify(schemaColorMixer)} 
                  </script>
                </Helmet>
                <ColorMixer />
              </>
            }
          />
          <Route
            path="/color-converter"
            element={
              <>
                <Helmet>
                <title>Color Converter and Mixer Tool - HEX to RGB & RGB to HEX</title>
                  <meta name="description" content="Convert colors seamlessly between HEX and RGB formats with the Online Color Converter and Mixer Tool. Blend colors, adjust mix ratios, and generate unique hex codes for web and graphic design." />
                  <meta name="keywords" content="color converter, HEX to RGB, RGB to HEX, online color mixing, blend colors, hex code generator, color blending tool, free color mixer, web design colors, graphic design colors" />
                  <script type="application/ld+json">

                    {JSON.stringify(schemaColorConverter)} 
                  </script>
                </Helmet>
                <ColorConverter />
              </>
            }
          />
          <Route
            path="/online-htaccess-generator"
            element={
              <>
                <Helmet>
                <title>Online .htaccess Generator Tool - SEO, Redirects, and Security Rules</title>
                <meta name="description" content="Easily generate custom .htaccess files with our Online .htaccess Generator Tool. Configure SEO-friendly redirects, access control, caching, error pages, and more for optimized website performance and security." />
                <meta name="keywords" content=".htaccess generator, online .htaccess tool, create .htaccess file, SEO redirects, access control, caching rules, custom error pages, htaccess file generator, website security, optimize website performance" />
                <script type="application/ld+json">

                    {JSON.stringify(schemaHtaccessGenerator)} 
                  </script>
                </Helmet>
                <HtaccessGenerator />
              </>
            }
          />
          <Route
            path="/snake-game"
            element={
              <>
                <Helmet>
                  <meta
                    name="description"
                    content="Play the classic Snake Game online! Maneuver your snake, eat the food, and grow longer while avoiding collisions. Perfect for all ages and fun for hours."
                  />
                  <meta
                    name="keywords"
                    content="Snake Game, Classic Snake, Play Online Snake Game, Arcade Games, Fun Games, Retro Games"
                  />
                  <title>Play Snake Game Online - Classic Arcade Fun</title>

                  <script type="application/ld+json">
                  {JSON.stringify(schemaSnakeGame)} 
                  </script>
                </Helmet>
                <SnakeGame />
              </>
            }
          />

          <Route
            path="/hangman"
            element={
              <>
                <Helmet>
                  <title>Hangman Game: Test Your General Knowledge | Help Daily App</title>
                  <meta
                    name="description"
                    content="Play the General Knowledge Hangman Game! Test your knowledge, guess the word, and learn fun facts while enjoying this classic game online."
                  />
                  <meta
                    name="keywords"
                    content="Hangman Game, General Knowledge Quiz, Online Games, Word Guessing, Learn Fun Facts, Educational Games, Trivia Hangman"
                  />
                  <meta property="og:title" content="Hangman Game: Test Your General Knowledge | Help Daily App" />
                  <meta
                    property="og:description"
                    content="Enjoy the fun and challenge of Hangman! Answer trivia questions, guess the letters, and improve your knowledge with our online Hangman game."
                  />
                  <meta property="og:url" content="https://www.helpdailyapp.com/hangman" />
                  <meta
                    property="og:image"
                    content="https://www.helpdailyapp.com/images/help-daily-app.png"
                  />
                  <meta name="twitter:card" content="summary_large_image" />
                  <meta name="twitter:title" content="Hangman Game: Test Your General Knowledge | Help Daily App" />
                  <meta
                    name="twitter:description"
                    content="Play the Hangman Game and test your knowledge with trivia questions. Fun for kids and adults alike!"
                  />
                  <meta name="twitter:image" content="https://www.helpdailyapp.com/images/help-daily-app.png" />

                  <script type="application/ld+json">
                    {JSON.stringify(schemaHangmanGame)} 
                  </script>
                </Helmet>
                <Hangman/>
              </>
            }
          />
          <Route
            path="/in-out-game"
            element={
              <>
                <Helmet>
                  <title>In or Out Game - Interactive Online Fun Tool for All Ages</title>
                  <meta name="description" content="Play the exciting In or Out game online! Experience a visually engaging and interactive game where players respond to commands by stepping In or Out. Designed for kids, friends, and family, this tool offers dynamic gameplay with sound effects, visual highlights, and customizable intervals. Start your fun-filled session now!" />
                  <meta name="keywords" content="In or Out Game, Quick Reflexes Gam, office fun activity" />
                  <script type="application/ld+json">
                    {JSON.stringify(schemaDataInOutGame)} 
                  </script>
                </Helmet>
                <InOutGame />
              </>
            }
          /><Route path="/in-out-game" element={<InOutGame />} />
        </Routes>
        
        
        <Footer />
      </Router>
    </HelmetProvider>
  );  

};




export default App;
