import React, { useState } from "react";
import CryptoJS from "crypto-js";

const SHA1HashGenerator = () => {
  const [input, setInput] = useState("");
  const [hash, setHash] = useState("");
  const [copySuccess, setCopySuccess] = useState(""); 
  const handleGenerateHash = () => {
    const hashResult = CryptoJS.SHA1(input).toString();
    setCopySuccess("");
    setHash(hashResult);
  };

  const handleCopyToClipboard = () => {
    if (hash) {
      navigator.clipboard.writeText(hash).then(
        () => {
          setCopySuccess("Copied to clipboard!");
        },
        (err) => {
          setCopySuccess("Failed to copy.");
        }
      );
    }

  };
  return (
    <div className="container py-5">
      <div className="row">
        <div className="col-lg-3 d-none d-lg-block">
          <div className="border bg-light p-3 text-center vertical-ads">
            {/* Placeholder for Ads */}
          </div>
        </div>
        <div className="col-lg-6">
          <h1>SHA-1 Hash Generator</h1>
          <textarea
            className="form-control mb-3"
            rows="4"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Enter text to hash"
          ></textarea>
          <button className="btn btn-primary mb-3" onClick={handleGenerateHash}>
            Generate SHA-1 Hash
          </button>
          <div className="position-relative mb-3">
            <textarea
              className="form-control"
              rows="4"
              readOnly
              value={hash}
              placeholder="Hash result will appear here"
              style={{ paddingRight: "40px" }} // Space for the icon
            ></textarea>
            {hash && (
              <button
                className="btn copy-icon"
                onClick={handleCopyToClipboard}
                aria-label="Copy to clipboard"
                style={{
                  position: "absolute",
                  top: "20%",
                  right: "-10px",
                  transform: "translateY(-50%)",
                  backgroundColor: "transparent",
                  border: "none",
                  cursor: "pointer",
                  color: "#007bff",
                  fontSize: "1.2rem",
                }}
              >📋</button>
            )}
          </div>
          {copySuccess && <span className="text-success mt-2">{copySuccess}</span>}

            <h2>What is a SHA-1 Hash Generator?</h2>
            <p>A SHA-1 (Secure Hash Algorithm 1) hash generator is a cryptographic tool that generates a 160-bit hash value (typically represented as a 40-character hexadecimal number) from input data. SHA-1 is widely used for ensuring data integrity, creating digital signatures, and verifying file authenticity.</p>

            <h2>How Does SHA-1 Hashing Work?</h2>
            <p>The SHA-1 algorithm works by processing input data through a series of cryptographic transformations to produce a unique, fixed-length hash. The process includes the following steps:</p>
            <ol>
                <li><strong>Message Padding:</strong> The input data is padded so that its length becomes a multiple of 512 bits.</li>
                <li><strong>Message Segmentation:</strong> The padded message is divided into 512-bit blocks.</li>
                <li><strong>Hash Computation:</strong> Each block undergoes a series of 80 cryptographic operations involving bitwise logic, modular addition, and compression functions.</li>
                <li><strong>Final Hash Output:</strong> The result is a 160-bit hash value, represented as a 40-character hexadecimal string.</li>
            </ol>

            <h3>Example of SHA-1 Hashing</h3>
            <p>Here's an example of generating a SHA-1 hash in Python:</p>
             <div className="code-part">
            <code class="language-python">
            import hashlib<br/>

            # Input string<br/>
            input_data = "Hello, World!"<br/>
            # Generate SHA-1 hash<br/>
            sha1_hash = hashlib.sha1(input_data.encode()).hexdigest()<br/>

            print(sha1_hash)  # Output: 2ef7bde608ce5404e97d5f042f95f89f1c232871<br/>
            </code>
            </div>

            <h2>Applications of SHA-1 Hash Generator</h2>
            <p>SHA-1 hash generators have been used in various scenarios, including:</p>
            <ul>
                <li><strong>Data Integrity:</strong> Verifying that files or messages have not been altered during transmission or storage.</li>
                <li><strong>Digital Signatures:</strong> Ensuring the authenticity of documents or software updates.</li>
                <li><strong>Certificate Generation:</strong> Used in digital certificates for verifying identities (though largely replaced by SHA-256).</li>
                <li><strong>Checksums:</strong> Generating unique checksums for detecting errors in data transfer or storage.</li>
            </ul>

            <h2>Advantages and Limitations of SHA-1</h2>
            <p>While SHA-1 has been widely used, it has both advantages and limitations:</p>
            <ul>
                <li><strong>Advantages:</strong>
                    <ul>
                        <li>Efficient and quick hashing algorithm.</li>
                        <li>Generates compact hash values suitable for quick comparisons.</li>
                    </ul>
                </li>
                <li><strong>Limitations:</strong>
                    <ul>
                        <li>Prone to collision attacks, making it less secure for modern cryptographic applications.</li>
                        <li>Not recommended for hashing sensitive information like passwords or in secure communications.</li>
                    </ul>
                </li>
            </ul>

            <h2>How to Use a SHA-1 Hash Generator?</h2>
            <p>You can use SHA-1 hash generators online or implement them in programming languages. Here's an example in Python:</p>
             <div className="code-part">
            <code class="language-python">
            import hashlib<br/>

            # Input data<br/>
            data = "Generate SHA-1 Hash"<br/>
            # Create SHA-1 hash<br/>
            sha1 = hashlib.sha1(data.encode()).hexdigest()<br/>

            print(sha1)  # Output: Hash value<br/>
            </code>
            </div>

            <h2>Why SHA-1 is Being Replaced?</h2>
            <p>Despite its popularity, SHA-1 is being phased out due to vulnerabilities to collision attacks. Modern algorithms like SHA-256 and SHA-3 offer stronger security and are recommended for cryptographic applications today.</p>

            <h2>Conclusion</h2>
            <p>The SHA-1 hash generator is a useful tool for generating hashes and verifying data integrity, but its security limitations make it unsuitable for modern cryptographic needs. For applications requiring higher security, consider using SHA-256 or SHA-3 for hashing.</p>

        </div>
        <div className="col-lg-3">
          <div className="border bg-light p-3 text-center vertical-ads">
            {/* Placeholder for Ads */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SHA1HashGenerator;
