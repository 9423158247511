import React, { useState } from "react";

const Base64Decoder = () => {
  const [input, setInput] = useState("");
  const [output, setOutput] = useState("");
  const [copySuccess, setCopySuccess] = useState("");

  const handleDecode = () => {
    try {
      const decoded = atob(input);
      setOutput(decoded);
      setCopySuccess("");
    } catch (error) {
      setOutput("Invalid Base64 string.");
    }
  };

  const handleCopyToClipboard = () => {
    if (output) {
      navigator.clipboard.writeText(output).then(
        () => {
          setCopySuccess("Copied to clipboard!");
        },
        (err) => {
          setCopySuccess("Failed to copy.");
        }
      );
    }

  };
  
  return (
    <div className="container py-5">
      <div className="row">
        <div className="col-lg-3 d-none d-lg-block">
          <div className="border bg-light p-3 text-center vertical-ads">
            {/* Placeholder for Ads */}
          </div>
        </div>
        <div className="col-lg-6">
          <h1>Base64 Decoder</h1>
          <textarea
            className="form-control mb-3"
            rows="4"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Enter Base64 encoded string"
          ></textarea>
          <button className="btn btn-primary mb-3" onClick={handleDecode}>
            Base64 Decode
          </button>
          <div className="position-relative mb-3">
            <textarea
              className="form-control"
              rows="4"
              readOnly
              value={output}
              placeholder="Decoded result will appear here"
              style={{ paddingRight: "40px" }} // Space for the icon
            ></textarea>
            {output && (
              <button
                className="btn copy-icon"
                onClick={handleCopyToClipboard}
                aria-label="Copy to clipboard"
                style={{
                  position: "absolute",
                  top: "20%",
                  right: "-10px",
                  transform: "translateY(-50%)",
                  backgroundColor: "transparent",
                  border: "none",
                  cursor: "pointer",
                  color: "#007bff",
                  fontSize: "1.2rem",
                }}
              >📋</button>
            )}
          </div>
          {copySuccess && <span className="text-success mt-2">{copySuccess}</span>}
          


            <h2>What is Base64 Decoding?</h2>
            <p>Base64 decoding is the process of converting <a href="/base64-encoder">encoded data</a> in Base64 format back into its original form. It is a widely used encoding scheme designed to represent binary data as an ASCII string, making it suitable for transmission over text-based protocols like email and HTTP.</p>

            <h2>Why Use Base64 Encoding and Decoding?</h2>
            <p>Base64 encoding ensures that binary data (such as images, files, or non-printable characters) can be safely transferred or stored using systems that handle only textual data. Decoding reverses this process, restoring the original binary data from the Base64 representation.</p>

            <h3>How Does Base64 Decoding Work?</h3>
            <p>Base64 encoding converts data into a set of 64 characters (A-Z, a-z, 0-9, +, /) and uses <code>=</code> as a padding character when the input length isn't divisible by three. Decoding involves the following steps:</p>
            <ol>
                <li><strong>Reading the Encoded String:</strong> The input string must conform to the Base64 format, including proper padding.</li>
                <li><strong>Mapping Characters:</strong> Each Base64 character is mapped back to its corresponding binary value.</li>
                <li><strong>Reconstructing Data:</strong> The binary values are grouped and converted into their original format, whether it's text, an image, or another data type.</li>
            </ol>
            <p>For example, the <a href="/base64-encoder">Base64-encoded</a> string <code>SGVscCBEYWlseSBBcHA=</code> decodes to "Help Daily App."</p>

            <h3>Applications of Base64 Decoding</h3>
            <p>Base64 decoding is widely used across multiple fields, such as:</p>
            <ul>
                <li><strong>Web Development:</strong> Embedding images or other binary files in HTML or CSS as inline data.</li>
                <li><strong>Email Transmission:</strong> Encoding attachments to ensure compatibility across email systems.</li>
                <li><strong>API Communication:</strong> Transmitting binary data like JSON Web Tokens (JWT) in a text-based format.</li>
            </ul>

            <h3>How to Decode Base64?</h3>
            <p>Base64 decoding can be performed using programming languages or online tools. Here's a Python example:</p>
            <div className="code-part">
                <code class="language-python">
                    import base64

                    encoded_str = "SGVscCBEYWlseSBBcHA="
                    decoded_bytes = base64.b64decode(encoded_str)
                    decoded_str = decoded_bytes.decode("utf-8")

                    print(decoded_str)  # Output: Help Daily App
                </code>
            </div>
            <h3>Key Benefits of Base64 Decoding</h3>
            <p>Base64 decoding provides several advantages, including:</p>
            <ul>
                <li><strong>Cross-Platform Compatibility:</strong> Ensures binary data can be safely shared across different systems.</li>
                <li><strong>Ease of Implementation:</strong> Supported natively by most programming languages.</li>
                <li><strong>Error-Free Data Transmission:</strong> Avoids issues related to non-printable characters in binary data.</li>
            </ul>

            <h3>Conclusion</h3>
            <p>Base64 decoding plays a crucial role in data transmission, ensuring binary data remains intact when shared over text-based channels. Whether embedding an image in HTML or handling secure tokens in APIs, understanding Base64 decoding is essential for modern developers. 
            Stay tuned to our blog for more insights into <a href="/base64-encoder">encoding</a> techniques and their applications!</p>

            <p>Interested reading more about Base64. Find this Wiki <a href="https://en.wikipedia.org/wiki/Base64">link</a>. </p>

        </div>
        <div className="col-lg-3">
          <div className="border bg-light p-3 text-center vertical-ads">
            {/* Placeholder for Ads */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Base64Decoder;
