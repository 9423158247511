import React from "react";
import { Link } from "react-router-dom";
import logo from "./../white-logo.png";

const Footer = () => {
  return (
    <footer className="bg-dark text-light py-4">
      <div className="container">
        <div className="row">
          {/* Column 1: Logo */}
          <div className="col-lg-6 col-md-6 mb-3">
            <img src={logo} alt="Logo" style={{ maxWidth: "300px" }} />
            {/* <p className="mt-2">
              Help Daily App - Tools to make your daily tasks easier.
            </p> */}
          </div>

          {/* Column 2: Quick Links */}
          <div className="col-lg-2 offset-lg-4 col-md-6 mb-3">
            <ul className="list-unstyled">
            
              <li>
                <Link to="/about" className="text-light text-decoration-none">
                  About Us
                </Link>
              </li>
              <li>
                <Link to="/contact" className="text-light text-decoration-none">
                  Contact
                </Link>
              </li>
              <li>
                <Link
                  to="/privacy-policy"
                  className="text-light text-decoration-none"
                >
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </div>

        </div>

        {/* Footer Bottom */}
        <div className="text-center mt-4">
          <p className="mb-0">
            &copy; {new Date().getFullYear()} Help Daily App. All rights
            reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
