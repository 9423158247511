import React, { useState, useRef } from "react";

const InOutGame = () => {
  const [isRunning, setIsRunning] = useState(false);
  const [command, setCommand] = useState("");
  const [highlight, setHighlight] = useState(""); // "circle" or "rectangle"

  const intervalRef = useRef(null);
  const inSoundRef = useRef(null);
  const outSoundRef = useRef(null);

  const getRandomInterval = () => {
    return Math.random() * (3 - 1) * 1000 + 2000; // Random interval between 2 and 5 seconds
  };

  const issueCommand = () => {
    const isCircle = Math.random() > 0.5;
    const newCommand = isCircle ? "In" : "Out";

    setCommand(newCommand);
    setHighlight(isCircle ? "circle" : "rectangle");

    // Play sound based on the command
    if (newCommand === "In") {
        if (inSoundRef.current) {
            inSoundRef.current.play();
          }
          
    } else {
        if (outSoundRef.current) {
            outSoundRef.current.play();
          }
    }
  };

  const startGame = () => {
    if (!isRunning) {
      setIsRunning(true);
      issueCommand(); // Issue the first command immediately
      intervalRef.current = setInterval(() => {
        issueCommand();
      }, getRandomInterval());
    }
  };

  const stopGame = () => {
    setIsRunning(false);
    clearInterval(intervalRef.current);
    setCommand("");
    setHighlight("");
  };

  return (
    <>
      <div className="container py-5">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-6">
            <h1 className="mb-4 text-center">In or Out Game</h1>

            {/* Game Controls */}
            <div className="mb-4 text-center">
              <button
                className="btn btn-success me-2"
                onClick={startGame}
                disabled={isRunning}
              >
                Start Game
              </button>
              <button className="btn btn-danger me-2" onClick={stopGame}>
                Stop Game
              </button>
            </div>

            {/* Visual Game Area */}
            <div
              className="rectangle position-relative border"
              style={{
                width: "300px",
                height: "300px",
                backgroundColor: highlight === "rectangle" ? "green" : "white",
                margin: "0 auto",
              }}
            >
              <div
                className="circle d-flex align-items-center justify-content-center"
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  width: "150px",
                  height: "150px",
                  backgroundColor: highlight === "circle" ? "green" : "white",
                  borderRadius: "50%",
                  transform: "translate(-50%, -50%)",
                  border: "1px solid black",
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                  color: highlight === "circle" ? "white" : "black",
                }}
              >
                {command}
              </div>
            </div>
          </div>
        </div>

        {/* Sound Effects */}
        <audio ref={inSoundRef} src="/sounds/in.mp3" preload="auto"></audio>
        <audio ref={outSoundRef} src="/sounds/out.mp3" preload="auto"></audio>
      </div>

      <section className="in-or-out-game py-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-6">
              <h1 className="text-center mb-4">
                Play the "In or Out Game" Online – A Fun Challenge for All
              </h1>
              <div className="text-center mt-5">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube-nocookie.com/embed/aTMrQP3LjB0?si=jUOtWNF37YHa4DzR&amp;start=14"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                ></iframe>
                <p className="mt-3">
                  Watch on YouTube:{" "}
                  <a
                    href="https://www.youtube.com/watch?v=aTMrQP3LjB0"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://www.youtube.com/watch?v=aTMrQP3LjB0
                  </a>
                </p>
              </div>
              <p>
                Looking for a fun and interactive game to play online? The{" "}
                <strong>"In or Out Game"</strong> is here to bring excitement to
                your gatherings, whether it’s for kids, friends, or family. Step
                into the world of quick reflexes as you respond to commands of{" "}
                <strong>"In"</strong> or <strong>"Out"</strong>. Watch the
                dynamic visual changes and hear engaging sound effects as you
                play this thrilling circle-and-rectangle game.
              </p>

              <h2 className="mt-5">Why Play the In or Out Game Online?</h2>

              
              <ul>
                <li>
                  <strong>Dynamic Gameplay:</strong> Random intervals between 2
                  and 5 seconds keep the game unpredictable and fun. Visual
                  highlights for "In" and "Out" commands.
                </li>
                <li>
                  <strong>Engaging Sound Effects:</strong> Real-time audio
                  feedback enhances the immersive experience.
                </li>
                <li>
                  <strong>Simple and Fun for All Ages:</strong> Easy to
                  understand and perfect for kids, teens, and adults alike.
                </li>
                <li>
                  <strong>Customizable Speed:</strong> Add more challenges with
                  increasing speed as you play. *(Optional for Future
                  Enhancements)*
                </li>
              </ul>

              <h2 className="mt-5">How to Play</h2>
              <ol>
                <li>Click "Start Game" to begin.</li>
                <li>
                  Follow the commands displayed inside the circle:
                  <ul>
                    <li>
                      <strong>"In":</strong> The circle turns green; stay
                      inside.
                    </li>
                    <li>
                      <strong>"Out":</strong> The rectangle outside the circle
                      turns green; step out.
                    </li>
                  </ul>
                </li>
                <li>Listen carefully to the sound cues for extra immersion.</li>
                <li>
                  Keep playing until you stop the game. How long can you last?
                </li>
              </ol>

              <p className="mt-4">
                The <strong>"In or Out Game"</strong> is not just a game; it’s a
                fun, interactive challenge that tests your reflexes and brings
                people together. Start playing now and make every moment count!
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default InOutGame;
