import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="container py-5">
      <h1>Privacy Policy</h1>
      <p>
        Your privacy is important to us. This Privacy Policy explains how we
        collect, use, and share information about you when you use our website
        and services.
      </p>

      <h2>Information We Collect</h2>
      <p>
        We may collect the following types of information:
        <ul>
          <li>Personal identification information (Name, email address, etc.)</li>
          <li>Non-personal information (Browser type, device type, etc.)</li>
        </ul>
      </p>

      <h2>How We Use Your Information</h2>
      <p>
        The information we collect is used for:
        <ul>
          <li>Providing and improving our services</li>
          <li>Communicating with you</li>
          <li>Personalizing your user experience</li>
        </ul>
      </p>

      <h2>Sharing Your Information</h2>
      <p>
        We do not share your personal information with third parties except as
        necessary to provide our services or as required by law.
      </p>

      <h2>Your Rights</h2>
      <p>
        You have the right to access, update, or delete your personal
        information. Please contact us if you wish to exercise any of these
        rights.
      </p>

      <h2>Changes to This Privacy Policy</h2>
      <p>
        We may update this Privacy Policy from time to time. Please review it
        periodically for any changes.
      </p>

      <h2>Contact Us</h2>
      <p>
        If you have any questions about this Privacy Policy, please contact us
        at "let us talk @ helpdailyapp.com".
      </p>
    </div>
  );
};

export default PrivacyPolicy;
